import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ProductService } from "../../services/ProductService";
import { useEffect } from "react";
import Preloader from "../../common/components/Preloader";
import Header from "../../common/components/Header";
import { ToastContainer } from "react-toastify";
import ProjectHeader from "../../components/Projects/ProjectHeader";
import "./ProductDetails.css";
import Spinner from "../../components/Spinner";
import config from '../../config.json';

function ProductDetails(props) {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");

  const _ = new ProductService();

  const fetch = () => {
    _.fecthProduct(id).then((res) => {
      setTitle(res.data.title);
      setDescription(res.data.description);
      setImage(res.data.image);
      setLink1(res.data.link1);
      setLink2(res.data.link2);
      setLink3(res.data.link3);
    });
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <React.Fragment>
      <Preloader />
      <Header />
      <ToastContainer />
      <ProjectHeader title={title} />
      <div className="container">
        <section class="container mt-5">
          <div class="product-info mx-3">
            <div className="d-flex justify-content-center">
              <img
                width={200}
                style={{ borderRadius: 20 }}
                src={`${config.prod}/uploads/${image}`}
                alt=""
                id="featured-image"
              />
            </div>
            <p
              className="text-center w-100"
              style={{ lineHeight: 2, fontSize: 18 }}
            >
              {description && <>{description}</>}
            </p>
            <div className="w-100 container d-flex justify-content-center align-items-center">
              <a
                className="text-center"
                target="_blank"
                href={link1}
                rel="noreferrer"
              >
                <p className="w-100">
                  {" "}
                  <i className="fa fa-edit" style={{ color: "#1963BB" }} />
                  &nbsp; View Presentation
                </p>
              </a>
              <a
                className="text-center mx-5"
                target="blank"
                href={link3}
                rel="noreferrer"
              >
                <p className="w-100">
                  {" "}
                  <i className="fa fa-video" style={{ color: "#1963BB" }} />
                  &nbsp; View Demo
                </p>
              </a>
            </div>

            <div></div>
          </div>

          <div class="img-card w-100">
            {link2 !== "" ? (
              <img
                src={`${config.prod}/uploads/${link2}`}
                alt="project-img"
                width={"100%"}
                height={"auto"}
                style={{ borderRadius: 50, position: "relative" }}
              />
            ) : (
              <div align="center" className="p-4 bg-light mt-4">
                <Spinner />
                <br/><br/>
                <p className="d-block text-center title">
                  Our content is currently unavailable, but please stay tuned
                  for updates
                </p>
              </div>
            )}
          </div>
        </section>
        <div className="mt-5"></div>
      </div>
    </React.Fragment>
  );
}

export default ProductDetails;
