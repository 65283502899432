const TrainingSubServicesItem = props => {

    return (
        <div className="card">
            
                <div className="card-body">
                    <p>{props.subservices.description} </p>aaaaaaa
                </div>
        </div>
    );
}

export default TrainingSubServicesItem;