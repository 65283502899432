/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import './service.css';

const ServiceItem = (props) => {
  const navigate = useNavigate();



  const goTo = () => {
    navigate(`/servicedetails/${props.id}`);
  }

  useEffect(()=>{
    fetch();
  },[]);
  
  const onClickHandler = (e) => {
    e.preventDefault();
    navigate("services/" + props.id.replace(/\s/g, ""), {
      state: { id: props.id },
    });
  };
  return (
    <div className="col-lg-3 col-sm-12 wow fadeInLeft" data-wow-delay="0.3s">
      <div
        style={{ height: 370 }}
        onClick={goTo}
        className={`service_item service-item-six color-${props.index} text-center mt-30`}
      >
        <div className="icon">
          <i className="fal fa-laptop-code"></i>
        </div>
        <h4 className="title title-hover" style={{ fontSize: "1.5em" }}>
          {props.title}
        </h4>
        <p>{props.description}</p>
      
        {/* <a  className="service-link"><i
                    className="fal fa-angle-right"></i> Explore
                    More</a> */}
      </div>
    </div>
  );
};

export default ServiceItem;
