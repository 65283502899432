import React, { useEffect, useState } from "react";
import Preloader from "../../../common/components/Preloader";
import Header from "../../../common/components/Header";
import ProjectHeader from "../../Projects/ProjectHeader";
import { ProductService } from "../../../services/ProductService";
import "./solution.css";
import { useNavigate } from "react-router-dom";
import config from "../../../config.json";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Achievements() {
  const _ = new ProductService();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = () => {
      _.fetchExternal().then((res) => {
        setData(res.projects);
      });
    };
    fetch();
  }, []);


  const navigate = useNavigate();

  return (
    <div>
      <Preloader />
      <Header />
      <ProjectHeader title="Achievements" />
      <Carousel  responsive={responsive} align="center" className="container mt-5">
        {data.map((item, index) => (
          <div>
            <div
              className="panel"
              onClick={() => navigate(`/projectDetails/${item._id}`)}
                            key={index}
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${config.prod}/uploads/${item?.link2})`,
                height: 300,
                borderRadius: 25,
                width: "90%",
                margin: 5,
              }}
            ></div>
             <div align="end">
             <h3
              style={{cursor:'pointer'}}
              
              className="text-primary mt-4 mx-5" 
            >
              {item.title} <i className="mx-4 fa fa-arrow-right" />{" "}
            </h3>
             </div>
          </div>
        ))}
      </Carousel>
      <div className="mt-5"></div>
    </div>
  );
}

export default Achievements;
