import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/esm/Card";
import Button from "react-bootstrap/esm/Button";

function EventsBody() {
  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col lg={4} md={8} sm={12}>
          <input
            style={{
              height: 70,
              border: "1px solid transparent",
              color: "#0052B4",
              backgroundColor: "#F5FAFF",
            }}
            id="search"
            element="input"
            type="text"
            placeholder="Find your event ..."
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={4} md={8} sm={12}>
          <Card>
            <div className="card-margin mt-4">
              <div className="card-header no-border">
                <h5 className="card-title">MOM</h5>
              </div>
              <div className="card-body pt-0">
              <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                      <span className="widget-49-date-day">09</span>
                      <span className="widget-49-date-month">apr</span>
                    </div>
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">
                            Event Title
                      </span>
                      <span className="widget-49-meeting-time">
                        12:00 to 13.30 Hrs
                      </span>
                    </div>
                  </div>
                  <ol className="widget-49-meeting-points">
                    <li className="widget-49-meeting-item">
                      <span>Event detail#1</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event details#2</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event detail#3</span>
                    </li>
                  </ol>
                  <div className="widget-49-meeting-action">
                    <a
                      href="/event-detail/1"
                      className="btn btn-sm btn-flash-border-primary"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={8} sm={12}>
          <Card>
            <div className="card-margin mt-4">
              <div className="card-header no-border">
                <h5 className="card-title">MOM</h5>
              </div>
              <div className="card-body pt-0">
              <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                      <span className="widget-49-date-day">09</span>
                      <span className="widget-49-date-month">apr</span>
                    </div>
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">
                            Event Title
                      </span>
                      <span className="widget-49-meeting-time">
                        12:00 to 13.30 Hrs
                      </span>
                    </div>
                  </div>
                  <ol className="widget-49-meeting-points">
                    <li className="widget-49-meeting-item">
                      <span>Event detail#1</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event details#2</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event detail#3</span>
                    </li>
                  </ol>
                  <div className="widget-49-meeting-action">
                    <a
                      href="/event-detail/1"
                      className="btn btn-sm btn-flash-border-primary"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={8} sm={12}>
          <Card>
            <div className="card-margin mt-4">
              <div className="card-header no-border">
                <h5 className="card-title">MOM</h5>
              </div>
              <div className="card-body pt-0">
              <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                      <span className="widget-49-date-day">09</span>
                      <span className="widget-49-date-month">apr</span>
                    </div>
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">
                            Event Title
                      </span>
                      <span className="widget-49-meeting-time">
                        12:00 to 13.30 Hrs
                      </span>
                    </div>
                  </div>
                  <ol className="widget-49-meeting-points">
                    <li className="widget-49-meeting-item">
                      <span>Event detail#1</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event details#2</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event detail#3</span>
                    </li>
                  </ol>
                  <div className="widget-49-meeting-action">
                    <a
                      href="/event-detail/1"
                      className="btn btn-sm btn-flash-border-primary"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        <Col lg={4} md={8} sm={12} className="mt-2">
          <Card>
            <div className="card-margin mt-4">
              <div className="card-header no-border">
                <h5 className="card-title">MOM</h5>
              </div>
              <div className="card-body pt-0">
              <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                      <span className="widget-49-date-day">09</span>
                      <span className="widget-49-date-month">apr</span>
                    </div>
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">
                            Event Title
                      </span>
                      <span className="widget-49-meeting-time">
                        12:00 to 13.30 Hrs
                      </span>
                    </div>
                  </div>
                  <ol className="widget-49-meeting-points">
                    <li className="widget-49-meeting-item">
                      <span>Event detail#1</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event details#2</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event detail#3</span>
                    </li>
                  </ol>
                  <div className="widget-49-meeting-action">
                    <a
                      href="/event-detail/1"
                      className="btn btn-sm btn-flash-border-primary"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={8} sm={12} className="mt-2">
          <Card>
            <div className="card-margin mt-4">
              <div className="card-header no-border">
                <h5 className="card-title">MOM</h5>
              </div>
              <div className="card-body pt-0">
              <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                      <span className="widget-49-date-day">09</span>
                      <span className="widget-49-date-month">apr</span>
                    </div>
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">
                            Event Title
                      </span>
                      <span className="widget-49-meeting-time">
                        12:00 to 13.30 Hrs
                      </span>
                    </div>
                  </div>
                  <ol className="widget-49-meeting-points">
                    <li className="widget-49-meeting-item">
                      <span>Event detail#1</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event details#2</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event detail#3</span>
                    </li>
                  </ol>
                  <div className="widget-49-meeting-action">
                    <a
                      href="/event-detail/1"
                      className="btn btn-sm btn-flash-border-primary"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} md={8} sm={12} className="mt-2">
          <Card>
            <div className="card-margin mt-4">
              <div className="card-header no-border">
                <h5 className="card-title">MOM</h5>
              </div>
              <div className="card-body pt-0">
              <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                      <span className="widget-49-date-day">09</span>
                      <span className="widget-49-date-month">apr</span>
                    </div>
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">
                            Event Title
                      </span>
                      <span className="widget-49-meeting-time">
                        12:00 to 13.30 Hrs
                      </span>
                    </div>
                  </div>
                  <ol className="widget-49-meeting-points">
                    <li className="widget-49-meeting-item">
                      <span>Event detail#1</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event details#2</span>
                    </li>
                    <li className="widget-49-meeting-item">
                      <span>Event detail#3</span>
                    </li>
                  </ol>
                  <div className="widget-49-meeting-action">
                    <a
                      href="/event-detail/1"
                      className="btn btn-sm btn-flash-border-primary"
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
     
       

      </Row>
      <Row>
            <Col align="center" className="mt-3">
                    <Button size="lg"> <i className="fa fa-spinner"></i> Load More</Button>
            </Col>
      </Row>
    </Container>
  );
}

export default EventsBody;
