
import Header from "../../../common/components/Header";
import Preloader from "../../../common/components/Preloader";
import HistoryHeader from "./HistoryHeader";

const HistoryPage = () => {
    //const {state} = useLocation();
    //const {projects} = state;


    return (
        <>
            <Preloader/>
            <Header/>
            <HistoryHeader/>

           

        </>
    );
};
export default HistoryPage;