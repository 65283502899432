import React from "react";
import "../css/Services.scss";
import Preloader from "../../common/components/Preloader";
import Header from "../../common/components/Header";
import ServiceItem from "./ServiceItem";
import ServicesHeader from "./ServicesHeader";
const ServicesPage = () => {
  const services = [
    {
      image: "https://www.daoc-it.com/images/12.jpg",
      name: "IT Services",
      description:
        "Step into a Future Powered by I-PACT!\nExperience a tech-driven revolution with I-PACT, the ultimate destination for IT development. Our expert consultants blend industry insight with innovation, guiding you through the tech landscape with finesse. Certified excellence is our hallmark; our consultants boast cutting-edge credentials for top-tier solutions. We intertwine tech brilliance with business acumen, crafting strategies that breed unmatched success. Challenges are conquered effortlessly, courtesy of our diverse skillset, covering every IT facet.\nYour strategic triumph is our mission. We integrate seamlessly into your planning, fusing business acumen and tech mastery for potent strategies. Tangible ROI is our pledge—each investment fuels substantial growth. Our track record shines with business transformations, fueled by tech prowess. Be the catalyst for change. With I-PACT, innovation is your partner, and transformation is the norm. Ready to forge ahead? Illuminate your IT journey with I-PACT. Your roadmap to tech excellence begins now! ",
    },
    {
      image:
        "https://cdn.majorel.com/wp-content/uploads/2019/12/09131220/majorel-consulting-team-scaled-5.jpeg",
      name: "Consulting",
      description:
        "Unleash the power of transformation with our adept business consultants, your partners in conquering the ever-shifting market landscape while boldly defying threats. Elevate your game with cutting-edge remote working tools that act as your shield against disruptions, ensuring unstoppable operations. We're not just consultants; we're catalysts for perpetual growth. Embracing change is in our DNA, and we've built a dynamic platform for relentless improvement. Seamlessly weaving together strategic consulting, game-changing technology, and industry wisdom, we engineer success for your organization.Unsure about your next move? Let us be your compass. Our knack for pinpointing challenges and needs is unparalleled. No puzzle is too complex. Drop us a line, and step into the digital realm where opportunities eagerly await your command. Your future begins with us.",
    },
    {
      image:
        "https://csweb.rice.edu/sites/g/files/bxs4941/files/2020-08/CSRemix.jpg",
      name: "Training",
      description:
        "In a world of endless possibilities, technology is the key. But let's face it, it's not about having it - it's about knowing how to wield its magic. That's where IPACT steps in, your ultimate guide to unlocking technology's true potential.Say goodbye to tech confusion! Our laser-focused courses are tailor-made to empower you with the skills to conquer the digital realm. From mastering web development wizardry to unraveling the mysteries of data science, our courses are the secret sauce for those who dare to invest in themselves and their teams.We don't just train, we transform. Imagine equipping your squads with the sharpest tech tools, turning departments into dynamos, and watching your whole workforce evolve into tech-savvy trailblazers.The future belongs to those who seize it - armed with knowledge, driven by innovation. Join us at IPACT and let's sculpt tomorrow together. Your journey to digital mastery begins now!",
    },
    {
      image:
        "https://ein-des-ein.com/wp-content/uploads/2021/09/Team-as-a-Service-TAAS-and-How-Businesses-Can-Benefit-From-It-Nowadays.png",
      name: "Team as Service",
      description:
        "Calling all leaders, visionaries, and team shapers! I-PACT Coaching is your backstage pass to unlocking your full potential. Whether you're an executive, a manager, or the driving force behind a team, we've got your back with a lineup of personalized and collective power-ups.Picture this: harnessing the untapped power of hypnosis, mastering mental finesse, and diving deep into the world of neuroscience coaching. At I-PACT, we're not just coaches - we're your secret weapon to conquer challenges and unleash unprecedented success. Struggling with self-doubt? Wrestling with emotions? Yearning to supercharge your performance? We're here to sculpt champions out of contenders, to transform doubts into triumphs, and to lead you on an odyssey of uncharted growth. Your journey towards becoming an empowered, unshakeable force begins here. I-PACT Coaching - where your evolution takes center stage. It's time to rewrite your story; let's make it legendary!",
    },
  ];
  const colors = ["white", "#0052B4", "white", "#0052B4"];
  return (
    <React.Fragment>
      <Preloader />
      <Header />
      <ServicesHeader />
      {services.map((service, index) => {
        return (
          <ServiceItem
            key={index}
            name={service.name}
            description={service.description}
            image={service.image}
            index={index}
            color={colors[index]}
            next={index !== services.length - 1 ? services[index + 1].name : ""}
          />
        );
      })}
    </React.Fragment>
  );
};
export default ServicesPage;
