import {useLocation} from "react-router-dom";
import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import ServicePageTitle from "../components/services/ServicePageTitle";
import TeamList from "../components/HomePage/teams/TeamList";

const TeamPage = () => {
    const {state} = useLocation();
    const {developers} = state;

    return (
        <>
            <Preloader/>
            <Header/>
            <ServicePageTitle title="Team"/>

            <TeamList developers={developers}/>
        </>
    );
};

export default TeamPage;