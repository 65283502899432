import React, { useState } from "react";
import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import PreviewTaasHeader from "../components/services/PreviewTaasHeader";
import { ToastContainer, toast } from "react-toastify";
import "../pages/css/previewtaas.css";
import "react-toastify/dist/ReactToastify.css";
import { TaasService } from "../services/TaasService";
import { useSelector } from "react-redux";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import Loader from "../components/Loader";

function PreviewTaas() {
  const [modal, setModal] = useState(false);

  const projectName = localStorage.getItem("projectName");
  const projectDescription = localStorage.getItem("projectDescription");
  const projectFeatures = localStorage.getItem("projectFeatures");
  const selectedOptions = JSON.parse(localStorage.getItem("selectedOptions"));
  const [selected, setSelected]=useState([]);
  const user_ = useSelector((state) => state.user.user);

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const notes = localStorage.getItem("notes");

  const [submitted, setSubmitted]=useState(false);

  const taas = new TaasService();

  const handleRequest = async () => {
    await taas
      .requestService({
        projectName,
        projectDescription,
        projectFeatures,
        projectRequiredSkills: selectedOptions.map((item)=>item.id),
        projectTeam: cart,
        user: user_.id,
        notes
      })
      .then((res) => {
        toast.info("Your request has been sent with success.");
        setSubmitted(true);

       /*  setTimeout(()=>{

        }) */
      });
  };

  const removeFromCart = (member) => {
    // Créez une nouvelle copie du panier sans le produit à supprimer
    const updatedCart = cart.filter((item) => item.id !== member.id);
    setCart(updatedCart);
    // Mettez à jour localStorage avec le nouveau panier
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    console.log(updatedCart + "/updated");
    // Affichez une notification de succès
    toast.success("You have removed a member from your team.");
  };

  const calculerTotalPanier = () => {
    let total = 0;

    // Parcourez le panier et ajoutez le prix de chaque item au total
    for (const item of cart) {
      total += item.price;
    }

    return total;
  };

  const total = calculerTotalPanier();
  return (
    <div>
      <Preloader />
      <Header />
      <ToastContainer />
      <PreviewTaasHeader title="Preview Project" />

      <div class="container mt-5">
        <div class="wrapper wrapper-content animated fadeInRight">
          <div class="row">
            <div class="col-md-3">
              <div class="ibox">
                <h5>Project Summary</h5>
                <div class="ibox-content">
                  <span>Project name</span>
                  <h4 className="mt-3 text-primary">{projectName}</h4>
                  <span>Project description</span>
                  <h5 className="mt-3 text-primary">{projectDescription}</h5>
                  <span>Project features</span>
                  <br />
                  <p className="mt-3">
                    <small style={{ marginTop: 0 }} className="text-primary">
                      {projectFeatures}
                    </small>
                  </p>

                  <span className="">Required Skills</span>
                  <div className="d-block">
                    <p title={selectedOptions.map((item)=>item.skill)} className="mt-3 text-primary">
                      {selectedOptions.map((item,index)=>(
                        <small key={index}>{item.skill},</small>
                      ))}
                    </p>
                  </div>
                  <span>Notes</span>
                  <br />
                  <p className="mt-3">
                    <small style={{ marginTop: 0 }} className="text-primary">
                      {notes}
                    </small>
                  </p>
                  <span>Total</span>
                  <h2 className="text-primary mt-3">$ {total}</h2>
                  <hr />

                  <div class="m-t-sm">
                    <div class="btn-group">
                      <button 
                                      onClick={()=>setModal(true)}

                      className="btn btn-primary">
                        <i className="fa fa-check" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ibox">
                <h5 className="text-primary">Support</h5>
                <div class="ibox-content text-center">
                  <h3 className="text-primary">
                    <i class="fa fa-phone"></i> +216 25 783 001
                  </h3>
                  <span class="small">
                    Please contact with us if you have any questions. We are
                    available 24h.
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="ibox">
                <span class="pull-right">
                  (<strong>{cart.length}</strong>) members
                </span>
                <h5>Project Team</h5>
                {cart.map((item, index) => (
                  <div class="ibox-content" key={index}>
                    <div class="table-responsive">
                      <table class="table shoping-cart-table">
                        <tbody>
                          <tr>
                            <td width="90">
                              <div class="">
                                <picture>
                                  <img
                                    height={70}
                                    width={150}
                                    className=""
                                    src={`${process.env.REACT_APP_BACKEND_URL}uploads/${item.avatar}`}
                                    alt=""
                                  />
                                </picture>
                              </div>
                            </td>
                            <td class="desc">
                              <h3>
                                <a href="#role" class="text-primary">
                                  {item.role}
                                </a>
                              </h3>
                              <p class="small text-dark">
                                {item.firstname}
                                {"  "}
                                {item.lastname}
                              </p>

                              <div className="d-flex container row">
                                {item.technologies.map((j) => (
                                  <p
                                    style={{ borderRadius: 60, fontSize: 12 }}
                                    className="alert alert-primary mx-1"
                                  >
                                    <small>
                                      {"#"}
                                      {j}
                                    </small>
                                  </p>
                                ))}
                              </div>
                              <dl class="small m-b-none">
                                <dt>Bio</dt>
                                <dd className="text-justify">{item.bio}</dd>
                              </dl>

                              {/* <div class="m-t-sm">
                               
                                <button  
                                                        onClick={() => removeFromCart(item)}
                                title="Remove member"
                                class="btn btn-danger" style={{width:40,height:40}}>
                                  <i class="fa fa-trash"></i> 
                                </button>
                              </div> */}
                            </td>

                            <td>
                              <h4 className="text-primary">
                                ${item.price}/{item.pricePerPeriod}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}

                <div class="ibox-content">
                  <button
                    onClick={() => setModal(true)}
                    class="btn btn-primary pull-right w-25"
                  >
                    <i class="fa fa fa-shopping-cart"></i> Confirm your cart
                  </button>

                  <a href="/services/Teamasservice" class="btn btn-light w-25">
                    <i class="fa fa-arrow-left"></i>&nbsp; Continue Editing
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PureModal
        width={"500px"}
        header="&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;Confirm Operation"
        footer={
          !submitted ?
          <div className="d-block mx-3">
             <div className="col-12 mx-3">
              <button
                onClick={handleRequest}
                className="btn btn-primary w-100"
                style={{ fontSize: 14 }}
              >
                Submit
              </button>
            </div>
            <div className="col-12 mx-3">
              <button
              onClick={()=>setModal(false)}
              className="btn btn-danger w-100" style={{ fontSize: 14 }}>
                Cancel
              </button>
            </div>
           
          </div>
          :
          <div className="d-block mx-3">
             <div className="col-12 mx-3">
              <button
                disabled
                onClick={handleRequest}
                className="btn btn-primary w-100"
                style={{ fontSize: 14 }}
              >
                Submit
              </button>
            </div>
            <div className="col-12 mx-3">
              <button
              
              onClick={()=>setModal(false)}
              className="btn btn-danger w-100" style={{ fontSize: 14 }}>
                Close
              </button>
            </div>
           
          </div>
        }
        isOpen={modal}
        closeButton="close"
        closeButtonPosition="bottom"
        onClose={() => {
          setModal(false);
          return true;
        }}
      >
        {
            !submitted ?
            <p style={{fontSize:18}} className="pt-3 text-center w-100">Are you sure to confirm your request ?</p>
            :
            <React.Fragment>
                <Loader/>
                <br/>
                <p className="text-center text-primary" style={{fontWeight:'bold'}}>
                    <i className="fa fa-check"/> Request Has Been Sent With Success</p>
            </React.Fragment>
        }
      </PureModal>
    </div>
  );
}

export default PreviewTaas;
