import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import config from '../../config.json';

const api = axios.create({
  baseURL: `${config.prod}`,
 // baseURL: `${process.env.DEPLOY_BACKEND_URL}`,
  withCredentials: true,
});

export const login = createAsyncThunk("user/login", async (credentials) => {
  const response = await api.post("/user/login", credentials);
  return response.data;
});

export const refreshToken = createAsyncThunk("user/refresh", async () => {
  const response = await api.get("/user/refresh", { withCredentials: true });
  return response.data;
});

const userSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    loginStatus: "idle",
    refreshStatus: "idle",
    error: null,
    user: null,
  },
  reducers: {
    deleteToken: (state) => {
      localStorage.removeItem("token");
      state.token = "";
      state.loginStatus = "idle";
      state.refreshStatus = "idle";
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    setUser: (state, action) => {
      state.token = action.payload;
      const decoded = jwtDecode(state.token);
      const user = decoded.user;
      state.user = user;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("hello");
        state.loginStatus = "succeeded";
        state.token = action.payload.accessToken;
        localStorage.setItem("token", action.payload.accessToken);
        state.error = null;
        const decoded = jwtDecode(state.token);
        const user = decoded.user;
        state.user = user;
        console.log(user);
      })
      .addCase(login.rejected, (state, action) => {
        state.loginStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(refreshToken.pending, (state) => {
        state.refreshStatus = "loading";
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.refreshStatus = "succeeded";
        state.token = action.payload.accessToken;
        localStorage.setItem("token", action.payload.accessToken);
        state.error = null;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.refreshStatus = "failed";
        state.error = action.error.message;
        localStorage.removeItem("token");
        window.location.href = "/";
      });
  },
});

export const { deleteToken, setToken, setUser, logout } = userSlice.actions;

export default userSlice.reducer;
