import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from './reducers/userSlice';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/signin');
  };

  return (
    <button
      onClick={handleLogout}
      style={{
        backgroundColor: '#0052B4',
        color: '#fff',
        border: '2px solid #fff',
        borderRadius: '5px',
        padding: '10px 20px',
        fontSize: '20px',
        fontWeight: 'normal',
        cursor: 'pointer',
      }}
    >
      Logout
    </button>
  );
};

export default Logout;
