import "./App.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import React, { useEffect } from "react";
import jquery from "jquery";
import { Navigate } from "react-router";
import useScript from "./common/hooks/useScript";
import HomePage from "./pages/HomePage";
import ConsultingPage from "./pages/ConsultingPage";
import ScrollToTop from "./common/helpers/ScrollToTop";
import CoachingPage from "./pages/CoachingPage";
import ItDevelopmentPage from "./pages/ItDevelopmentPage";
import ProjectsPage from "./pages/ProjectsPage";
import BackToTop from "./common/components/BackToTop";
import Footer from "./common/components/Footer";
import TrainingPage from "./pages/TrainingPage";
import TeamAsServicePage from "./pages/TeamAsServicePage";
import TeamPage from "./pages/TeamPage";
import Contact from "./components/HomePage/contact/Contact";
import Findus from "./pages/AboutPages/FindusPage/FindusPage";
import HistoryPage from "./pages/AboutPages/HistoryPage/HistoryPage";
import AboutUsPage from "./pages/AboutPages/AboutUsPage";
import Events from "./pages/Events/Events";
import ServicesPage from "./pages/ServicesPages/ServicesPage";
import TrainingIndex from "./pages/Training/TrainingIndex";
import LoginForm from "./pages/login/LoginForm";
import ResetForm from "./pages/ResetForm";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Logout from "./pages/Logout";
import { useDispatch } from "react-redux";
import { login, setUser } from "./pages/reducers/userSlice";
import RegisterForm from "./pages/Signup/RegisterForm";
import Detail from "./components/HomePage/events/Detail";
import PreviewTaas from "./pages/PreviewTaas";
import Inquiry from "./pages/Inquiry";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import ServiceDetails from "./components/HomePage/Services/ServiceDetails";
import Achievements from "./components/HomePage/projects/Achievements";
import Solutions from "./components/HomePage/projects/Solutions";
import ServicesList from "./components/HomePage/Services/ServicesList";
import Header from "./common/components/Header";

window.$ = window.jQuery = jquery;

const App = () => {
  useScript("assets/js/bootstrap.bundle.min.js");
  useScript("assets/js/slick.min.js");
  useScript("assets/js/jquery.magnific-popup.min.js");
  useScript("assets/js/isotope.pkgd.min.js");
  useScript("assets/js/imagesloaded.pkgd.min.js");
  useScript("assets/js/jquery.inview.min.js");
  useScript("assets/js/jquery.easypiechart.min.js");
  useScript("assets/js/jquery.syotimer.min.js");
  useScript("assets/js/wow.min.js");
  useScript("assets/js/main.js");
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(setUser(token));
    }
  }, [dispatch]);
  return (
    <>
      <HashRouter>

        <ScrollToTop />

        <Routes>
          <Route
            exact
            path="services/teamAsService"
            element={<TeamAsServicePage />}
          />
          <Route exact path="services/previewTaas" element={<PreviewTaas />} />
          <Route
            exact
            path="services/consulting"
            element={<ConsultingPage />}
          />
          <Route exact path="/signup" element={<RegisterForm />} />

          <Route exact path="services/training" element={<TrainingPage />} />
          <Route exact path="services/coaching" element={<CoachingPage />} />
          <Route
            exact
            path="services/ItDevelopment"
            element={<ItDevelopmentPage />}
          />
          <Route exact path="projects" element={<ProjectsPage />} />
          <Route exact path="solutions" element={<Solutions />} />
          <Route exact path="achievements" element={<Achievements />} />
          <Route exact path="team" element={<TeamPage />} />
       
          <Route path="/" element={<HomePage />} />

          <Route path="/home" redirectTo="/" element={<HomePage />} />
          <Route path="/findus" element={<Findus />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/events" element={<Events />} />
          <Route path="/services/:id" element={<ServicesPage />} />
          <Route path="/servicedetails/:id" element={<ServiceDetails />} />

          <Route path="/signin" element={<LoginForm />} />
          <Route path="/training" element={<TrainingIndex />} />
          <Route path="/forgot-password" element={<ResetForm />} />
          <Route path="/reset/:id/:token" element={<ResetPasswordPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/event/:eventId" element={<Detail />} />

          <Route path="/projectDetails/:id" element={<ProductDetails />} />
          <Route path="/inquiry" element={<Inquiry />} />

          <Route
            exact
            path="services_"
            element={<ServicesList />}
          />

        </Routes>
        <BackToTop />
        <Footer />
      </HashRouter>
    </>
  );
};

export default App;
