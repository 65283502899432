import React from 'react';
import Header from '../../common/components/Header';
import Preloader from '../../common/components/Preloader';
import TrainingBody from './TrainingBody';
import TrainingHeader from './TrainingHeader';

function TrainingIndex(props) {
    return (
        <div>
            <Preloader/>
            <Header/>
            <TrainingHeader/>
            <TrainingBody/>
            
        </div>
    );
}

export default TrainingIndex;