import React from "react";
import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import { ToastContainer } from "react-toastify";
import ServicePageTitle from "../components/services/ServicePageTitle";
import InquiryForm from "./InquiryForm";

function Inquiry(props) {


  return (
    <div>
      <Preloader />
      <Header />
      <ToastContainer />
      <ServicePageTitle title="Inquiry us" />
      <InquiryForm/>
    </div>
  );
}

export default Inquiry;
