const ProjectDetailsItem = props => {
    return (
        <>

            <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="service-item-eight mb-30">
                    <div className="service-img" align="center">
                        <img style={{height:200,width:"auto"}} alt={props.title} src={`${process.env.REACT_APP_BACKEND_URL}uploads/${props.image}`} />
                    </div>
                    <div className="services-overlay">
                        <div className="icon"><i title="Visit website" className="fal fa-link"></i></div>
                        <h4 className=" text-center"><a href="#" className="text-white">{props.title}</a></h4>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>


        </>
    );
}
export default ProjectDetailsItem;