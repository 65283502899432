import React, { useState } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login, setUser } from '../reducers/userSlice';


import signin from './ipact/v2-bleu.jpg';
import Header from '../../common/components/Header';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
    }

    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {

      dispatch(login(formData))
      .unwrap()
      .then(()=> {
        navigate('/')
      })
      .catch((error) => {
        console.log(error)
      })


    } catch (error) {
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <div
    style={{
      backgroundImage: `url(${signin})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: 'white',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div className="header" style={{ padding: '60px' }}>
      <Header />
    </div>
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="mx-auto text-center text-md-start my-5">
            <div className="d-flex flex-column justify-content-center h-100 align-items-center">
              <MDBIcon icon="user" size="3x" className="mb-3 text-white" style={{ marginLeft: '80px' }} />
              <h1
                className="fw-normal mb-3 ps-5 pb-3 text-white"
                style={{ letterSpacing: '1px', fontSize: '30px', marginLeft: '80px' ,    fontFamily: 'Arial, sans-serif'}}
              >
                Sign In
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="mb-4 mx-5 w-100 d-flex align-items-center">
                  <MDBInput
                    wrapperClass="w-100"
                    id="email"
                    name="email"
                    type="email"
                    size="lg"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    style={{ backgroundColor: 'transparent', border: '1px solid white', color: 'white' }}
                    placeholder="Enter your email address"
                  />
                </div>
                {formErrors.email && <p className="login-error-message">{formErrors.email}</p>}

                <div className="mb-4 mx-5 w-100 d-flex align-items-center">
                  <MDBInput
                    wrapperClass="w-100"
                    id="password"
                    name="password"
                    type="password"
                    size="lg"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    style={{ backgroundColor: 'transparent', border: '1px solid white', color: 'white' }}
                    placeholder="Enter your password"
                  />
                </div>
                {formErrors.password && <p className="login-error-message">{formErrors.password}</p>}

                <MDBBtn
                        type="button"
                        className="mb-3 px-4 mx-5 w-100"
                        style={{ backgroundColor: '#0052B4', fontSize: '16px',   fontFamily: 'Arial, sans-serif' }}
                        onClick={handleSubmit}
                >
                  Sign In
                </MDBBtn>
              </form>

              {error && <p className="login-error-message">{error}</p>}

              <p className="mb-5 pb-lg-5 ms-5" style={{ marginLeft: '70px',    fontFamily: 'Arial, sans-serif' }}>
                <Link to="/forgot-password" className="text-white">
                  Forgot password?
                </Link>
              </p>
              <p className="ms-5" style={{ marginLeft: '70px' ,   fontFamily: 'Arial, sans-serif'}}>
                Don't have an account? <Link to="/signup" className="text-white">Register here</Link>
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  </div>
);
};

export default LoginForm;