import SubServiceItem from "./SubServiceItem";

const SubServicesList = (props) => {
  return (
    <section className="faq-section-two soft-blue-bg section-gap">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className=" mb-md-gap-60">
              {/*   <img
                                src={"./assets/img/about-intro-thumb.jpg"} alt="Image"/>
                            <a href="" className="video-popup"><i className="fas fa-play"></i></a> */}
              <div className="col-lg-6 col-md-10">
                <div className="">
                  <div className="section-title  mb-40">
                  {/*   <span className="title-dark">
                      Search Enging Optimization
                    </span> */}
                    <h2 className="title">General Description.</h2>
                  </div>
                  <span className="text-dark text-justify" style={{width:500}}>{props.description}</span>
                 {/*  <div className="service-support-btn">
                    <p>Make A Call</p>
                    <span>897 876 76 56 4</span>
                    <i className="fal fa-phone"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="accordion-four" id="accordionExampleTwo">
              {props.subServices && (
                <>
                  {props.subServices.map((subService, index) => (
                    <SubServiceItem
                      index={index}
                      key={subService.id}
                      id={subService.id}
                      title={subService.name}
                      description={subService.description}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubServicesList;
