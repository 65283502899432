import React, { useState, useEffect } from "react";
import Rating from "./Rating.js";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import config from '../../../config.json';

const EventItem = ({ event }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year} / ${month} / ${day}`;
  };

  const userToken = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isParticipated, setIsParticipated] = useState(false);

  useEffect(() => {
    const checkParticipation = async () => {
      if (!userToken) {
        setIsParticipated(false);
        return;
      }

      const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
      const userId = tokenPayload.id;

      if (event.participants.includes(userId)) {
        setIsParticipated(true);
      } else {
        setIsParticipated(false);
      }
    };

    checkParticipation();
  }, [event.participants, userToken]);

  const handleParticipate = async (eventId) => {
    try {
      if (!userToken) {
        navigate("/signin");
        return;
      }

      const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
      const userId = tokenPayload.id;

      const response = await axios.post(
        `${config.prod}/events/participate/${event._id}`,
        { userId: userId },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setMessage(response.data.message);
    } catch (error) {
      setMessage("tu déja participer a cette event.");
    }
  };

  return (
    <div
      className="card"
      style={{cursor:'pointer', height: "auto", border: "none", padding: 9, borderRadius: "1%" }}
      onClick={()=>{navigate(`/event/${event?._id}`)}}
    >
      <div className="card-header">
      <img
        src={`${config.prod}/${event?.image}`}
        alt="event"
        top
        style={{ objectFit: "contain", height: 300, width: 500 }}
      />
      </div>

      <div className="card-body d-flex justify-content-between align-items-center">
        <h6 className="container mb-0 fw-bold" style={{ fontSize: "16px" }}>
          {event.title}
        </h6>
      </div>
      <div className="card-body d-block container ">
      <p className="mx-4 d-flex align-items-center justify-content-center">
          <Rating color={"#013174"} value={event.rating} />{" "}
        </p>
        <small className="small text-center mb-2 mx-3" style={{ fontSize: "14px" }}>
          Date : {formatDate(event.date)}
        </small>

      </div>
{/*       <div className="card-footer d-flex justify-content-center">
        <a
          style={{ backgroundColor: "#1963BB", height: 70, borderRadius: 5 }}
          href={`/#/event/${event._id}`}
          className="w-100 text-center text-white btn"
        >
          <span className="mt-3">
             Details
          </span>
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          style={{ border: "1px solid #1963BB", height: 70, borderRadius: 5 }}
          className="btn w-100"
          onClick={() => handleParticipate(event._id)}
        >
          Participate 
        </button>
      </div>
      {message && <p className="mt-3 text-center">{message}</p>} */}
    </div>
  );
};

export default EventItem;
