const ServiceDetails = props => {

    return (
        <section className="service-details-section section-gap">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                  
                    <div className="col-lg-12 col-md-10">
                        <div className="service-details-text-block">
                            <div className="section-title with-right-border mb-40">
                                <h2 className="title text-center">General Description.</h2>
                            </div>
                            <p className="text-color-2 mt-5 pt-3">
                                {props.description}
                            </p>
                          {/*   <div className="service-support-btn">
                                <p>Make A Call</p>
                                <span>897 876 76 56 4</span>
                                <i className="fal fa-phone"></i>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceDetails;