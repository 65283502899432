import { useForm } from "../../../common/hooks/form-hook";
import { useHttpClient } from "../../../common/hooks/http-hook";
import Input from "../../../common/util/Input";
import ReCAPTCHA from "react-google-recaptcha";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../../common/util/validators";
import { useEffect, useRef, useState } from "react";
import config from '../../../config.json';

const SITE_KEY = "6LdDjZYoAAAAAHGvzSYAqc0-P12S97r5HcRWiHXG";

const Contact = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isSending, setIsSending] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      phone: {
        value: "",
        isValid: false,
      },
      message: {
        value: null,
        isValid: false,
      },
    },
    false
  );
  const onChange = (value) => {
    setRecaptchaValue(value);
  };
  const placeSubmitHandler = async (event) => {
    event.preventDefault();
    setIsSending(true);
    try {
      await sendRequest(
        `${config.prod}/contact/postcontact`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          email: formState.inputs.email.value,
          phone: formState.inputs.phone.value,
          message: formState.inputs.message.value,
          formType: "contact",
         // recaptchaToken: recaptchaValue,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      setFormData(
        {
          name: {
            value: "",
            isValid: false,
          },
          email: {
            value: "",
            isValid: false,
          },
          phone: {
            value: "",
            isValid: false,
          },
          message: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(()=> {
    document.getElementById("contact").scrollIntoView({block:'start'})

  },[]);

  return (
    <section
      className="contact-section-three soft-blue-bg section-gap"
      id="contact"
      style={{ backgroundColor: "#0052b4", scrollBehavior:'smooth' }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-lg-6 col-md-10"
            style={{ textTransform: "none", color: "#ffffff" }}
          >
            <div className="section-title-two text-left mb-45">
              <span>Get in touch</span>
              <h2
                className="title"
                style={{ textTransform: "none", color: "#ffffff" }}
              >
                Contact us
              </h2>
            </div>
            <div className="form-area" style={{marginTop:-30}}>
              {!isSending ? (
                <form onSubmit={placeSubmitHandler}>
                  <div className="input-box input-circle mt-15">
                    <Input
                      id="name"
                      element="input"
                      type="text"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Please enter a valid name"
                      placeholder="Enter your name"
                      onInput={inputHandler}
                    />
                  </div>
                  <div className="input-box input-circle mt-15">
                    <Input
                      id="email"
                      element="input"
                      type="text"
                      validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                      errorText="Please enter a valid email"
                      placeholder="Enter your email"
                      onInput={inputHandler}
                    />
                  </div>
                  <div className="input-box input-circle mt-15">
                    <Input
                      id="phone"
                      element="input"
                      type="text"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Please enter a valid number"
                      placeholder="Enter your phone number"
                      onInput={inputHandler}
                    />
                  </div>
                  <div className="input-box input-circle mt-15">
                    <Input
                      id="message"
                      element="textarea"
                      type="text"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Please enter a valid message"
                      placeholder="Enter your message"
                      onInput={inputHandler}
                    />
                  </div>
             {/*       <div className="mb-4 mt-15">
                    <ReCAPTCHA sitekey={SITE_KEY} onChange={onChange} />
                  </div>  */}
                  <div align="center" className="input-box">
                    <button
                      style={{
                        borderRadius: 5,
                        height: 60,
                        width: '100%',
                        color: "white",
                      }}
                      className="btn btn-primary"
                      type="submit"
                      disabled={!formState.isValid}
                    >
                     <i className="fa fa-check"/> Send
                    </button>
                  </div>
                </form>
              ) : (
                <div className="text-center mt-100">
                  <div className="icon">
                    <i
                      style={{ color: "#002249" }}
                      className="fal fa-check fa-8x text-light"
                    ></i>
                    <h3 className="title text-light">Thank You for Getting in Touch!</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
