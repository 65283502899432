/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React from "react";


import "../../components/HomePage/home.css";
const Header = () => {




  return (
    <header className="header-four header-full-width sticky-header">
      <div className="header-navigation">
        <Navbar bg="" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/#/#home">
              <div className="site-logo" style={{ marginTop: 0 }}>
                <a href="">
                  <img
                    src={"./assets/img/ipact.png"}
                    width="150"
                    alt="IPACT"
                    className="main-logo mt-2"
                  />
                  <img
                    src={"./assets/img/ipact.png"}
                    width="150"
                    alt="IPACT"
                    className="sticky-logo"
                  />
                </a>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                style={{ marginLeft: "auto", marginRight: "auto" }}
                className="nav-links"
              >
                <Nav.Link
                  href="/#/#services"
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  Services
                </Nav.Link>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Nav.Link
                  href="/#/#projects"
                
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                    scrollBehavior:'smooth'
                  }}
                >
                  Releases
                  </Nav.Link>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Nav.Link
                  href="/#/#events"
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  News
                </Nav.Link>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Nav.Link
                  href="/#/#contact"
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  Contact
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="/#/inquiry" className="btn-inquiry text-white">
                  <p className="ml-2 mt-2 text-center">
                    Inquiry us &nbsp; <i className="fa fa-arrow-right" />
                  </p>
                </Nav.Link>
                {/*    <NavDropdown
                  title={
                    <span
                      className="text-login text-white"
                      style={{
                        fontSize: 22,
                        fontWeight: "normal",
                        lineHeight: "1",
                      }}
                    >
                      <i
                        className="fa fa-lock"
                        style={{ marginRight: "8px" }}
                      ></i>
                      Login
                    </span>
                  }
                  id="auth-dropdown"
                >
                  {user ? (
                    <NavDropdown.Item>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: 10 }}>
                          <p style={{ marginBottom: 0 }}>
                            Welcome, {user.firstname}
                          </p>
                        </div>
                      </div>
                      <Logout />
                    </NavDropdown.Item>
                  ) : (
                    <React.Fragment>
                      <button onClick={goToLogin} className="nav-item btn"  >Signin</button>
                      <button onClick={goToRegister} className="nav-item btn">Signup</button>
                    </React.Fragment>
                  )}
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
