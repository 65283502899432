
import Header from "../../../common/components/Header";
import Preloader from "../../../common/components/Preloader";
import FindusHeader from "./FindusHeader";

const Findus = () => {
    //const {state} = useLocation();
    //const {projects} = state;


    return (
        <>
            <Preloader/>
            <Header/>
            <FindusHeader/>

           

        </>
    );
};
export default Findus;