const ItSubServiceItem = props => {

    return (
        <div className="history-item wow fadeInUp" data-wow-delay="0.3s">
            <div className="history-thumb"
                 // style={{backgroundImage: `url(${props.image})`}} />
                 style={{backgroundImage: `url(assets/img/Content-Management-System.png)`}} />
            <div className="history-content">
                <span className="history-year">{props.name}</span>
                <h4 className="title">{props.name}</h4>
                <p>{props.description}</p>
            </div>
            <div className="number-box">
                <span>{props.index+1}</span>
            </div>
        </div>

    );
}

export default ItSubServiceItem;