import { useNavigate } from "react-router-dom";
import Goal from "../../../assets/img/goal.png";
import Innovative from "../../../assets/img/innovative.png";
import { useEffect, useRef, useState } from "react";
import './projectcss.css'

const ProjectsList = (props) => {
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const onClickHandler = (e) => {
    e.preventDefault();
    navigate("projects", { state: { projects: props.projects } });
  };

  const goto1 = () => {
      navigate("/solutions");
  }

  const goto2 = () => {
    navigate("/achievements");
  }


  useEffect(()=> {
    document.getElementById("projects").scrollIntoView({block:'start'})

  },[]);

  return (
    <section
      className="portfolio-area section-gap"
      id="projects"
      style={{ backgroundColor: "#01419a", height: 1000 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="section-title-two mb-50">
              <span className="title text-white">Releases</span>
              <h2 className="text-white" style={{ fontSize: 45 }}>
                We have so much stuff{" "}
              </h2>
            </div>
          </div>
        </div>
        {/*   <div align="center">
          <button
            style={{ border: "1px solid #fff", color: "white" }}
            className="btn"
            onClick={sliderRef?.slickPrev}
          >
            <FaChevronLeft />
          </button>
          &nbsp;
          <button
            style={{ border: "1px solid #fff", color: "white" }}
            className="btn"
            onClick={sliderRef?.slickNext}
          >
            <FaChevronRight />
          </button>
        </div> */}
        {/*  <Slider {...sliderSettings} ref={setSliderRef}>
          {props.projects.map((project, index) => (
            <div key={project.id}>
              <ProjectItem
                index={index}
                key={project.id}
                id={project.id}
                title={project.title}
                description={project.description}
                image={project.image}
              />
            </div>
          ))}
        </Slider> */}

        {/* <div className="col text-center">
            <a data-animation="fadeInUp" data-delay="1.1s" onClick={onClickHandler}
                       className="main-btn rounded-btn icon-right small-size text-center"
                       style={{marginTop: "5%"}}>
                        Our Projects <i className="fal fa-long-arrow-right"></i>
                    </a>
        </div> */}

        <div className="container" align="center">
          <div className="row mt-4">
            <div onClick={goto1} className="col-lg-6 col-sm-12 " style={{cursor:'pointer'}}>
              <img height={200} width={200} src={Innovative} alt="" />
              <h2 style={{fontSize:38}} className="text-white mt-4 mx-3">Our Products</h2>

            </div>
          
            <div onClick={goto2} className="col-lg-6 col-sm-12" style={{cursor:'pointer'}}>
              <img height={200} width={200} src={Goal} alt="" />
              
              <h1 style={{marginLeft:-40}} className="text-white   mt-4">Achievements</h1>
            </div>
          </div>
        </div>
      </div> 
    </section>
  );
};

export default ProjectsList;
