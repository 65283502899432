import { ReactWorldCountriesMap } from "react-world-countries-map";
import WorldMap from "react-svg-worldmap";

import Linkedin from "../../assets/img/linkedin_.png";
import Facebook from "../../assets/img/fb_.png";
import Instagram from "../../assets/img/instagram_.png";
import Canada from "../../assets/img/canada.png";
import Brasil from "../../assets/img/brasil.png";
import Portugal from "../../assets/img/portugal.webp";
import French from "../../assets/img/french.png";
import Tunisia from "../../assets/img/tunisia.png";

const Footer = () => {
  const current = new Date();
  const data = [
    { country: "ca", value: "" },
    { country: "br", value: "" },
    { country: "pt", value: "" },
    { country: "fr", value: "" },
    { country: "tn", value: "" },

  ];
  return (
    <footer className="footer-area  footer-area-two">
      <div className="container">
        <div className="row footer-widgets">
          <div className="col-lg-4 col-md-6 col-md-6 mt-5">
            <div className="widget contact-widget">
              <h4 className="widget-title">Contact</h4>

              <ul className="text-white footer-list pt-4">
                <li>
                  {" "}
                  <p className="text-white">
                    <i className="fa fa-envelope"></i>{" "}
                    <small className="">hello@ipactconsult.com</small>
                  </p>{" "}
                </li>
                <li>
                  <small>
                    {" "}
                    <i className="fa fa-phone"></i> +1 514 465 5030 {""}{" "}
                    <img
                      style={{marginLeft:35, borderRadius: 5 }}
                      src={Canada}
                      height={20}
                      width={30}
                      alt="Canada"
                    />
                  </small>
                </li>
                <li>
                  <small>
                    {" "}
                    <i className="fa fa-phone"></i>
                    <span className="mx-1"> +55 44 98 49 73 46 </span>
                  </small>{" "}
                  <img
                    style={{marginLeft:13, borderRadius: 5 }}
                    src={Brasil}
                    height={20}
                    width={30}
                    alt="Brasil"
                  />
                </li>
                <li>
                  <small>
                    {" "}
                    <i className="fa fa-phone"></i>
                    <span className="mx-1"> +351 91 30 26 023 </span>
                  </small>
                  <img
                    style={{marginLeft:22, borderRadius: 5 }}
                    src={Portugal}
                    height={20}
                    width={30}
                    alt="Portugal"
                  />
                </li>
                <li>
                  <small>
                    {" "}
                    <i className="fa fa-phone"></i>
                    <span className="mx-1"> +33 7 80 51 01 04 </span>
                  </small>
                  <img
                    className=""
                    style={{marginLeft:27, borderRadius: 5 }}
                    src={French}
                    height={20}
                    width={30}
                    alt="French"
                  />
                </li>
                <li className="">
                  <small>
                    {" "}
                    <i className="fa fa-phone"></i> +216 25 425 042{" "}
                  </small>
                  &nbsp;
                  <img
                    
                    style={{marginLeft:30, borderRadius: 5 }}
                    src={Tunisia}
                    height={20}
                    width={30}
                    alt="Tunisia"
                  />
                </li>

                <li>
                  <small>
                    {" "}
                    <i className="fa fa-location-arrow"></i>{" "}
                    <span className="mx-1">
                      Canada, Brasil, Portugal, France, Tunisia
                    </span>
                  </small>
                </li>
              </ul>
              <p className="text-white">
              Unlock your business's full potential with IPACT's IT expertise. Contact us today to accelerate innovation and achieve your digital transformation goals
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-5  mt-5">
            <div className="widget nav-widget">
              <h4 className="widget-title">Useful links</h4>
              <div className="footer-list mt-5 pt-3">
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/ipact-consult-inc/mycompany/"
                      className="text-white"
                    >
                      <i className="fal fa-angle-right"></i>{" "}
                      <img src={Linkedin} width={20} height={20} alt="" />{" "}
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/IPACTConsultinc"
                      className="text-white"
                    >
                      <i className="fal fa-angle-right"></i>{" "}
                      <img src={Facebook} width={20} height={20} alt="" />{" "}
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/ipact.consult/"
                      className="text-white"
                    >
                      <i className="fal fa-angle-right"></i>{" "}
                      <img src={Instagram} width={20} height={20} alt="" />{" "}
                      Instagram
                    </a>
                  </li>
                </ul>
                {/*                 <img style={{height:'20vh'}} src={MapImage} alt="IPACT Countries"/>
                 */}{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-10 col-sm-12" align="">
            <div className="widget nav-widget mt-5">
              <h4 className="widget-title">Ipact companies</h4>
            </div>
            <div className="logo mt-5" style={{marginLeft:-100}}>
              <WorldMap
                borderColor="white"
                tooltipTextColor="white"
                valuePrefix=""
                backgroundColor="#1963BB"
                color="#fff"
                size="lg"
                data={data}
              />
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p className="text-white">
            Copyright <i className="fa fa-copyright"></i>{" "}
            <a href="https://www.ipactconsult.com">IPACT Consult</a> -{" "}
            {current.getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
