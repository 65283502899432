/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceService } from "../../../services/ServiceService";
import { useEffect, useState } from "react";
import Preloader from "../../../common/components/Preloader";
import Header from "../../../common/components/Header";
import { ToastContainer } from "react-toastify";
import ServicePageTitle from "../../services/ServicePageTitle";
import InquiryForm from "../../../pages/InquiryForm";
import ServiceImage from "../../../assets/img/banner/banner-img.png";

const ServiceDetails = (props) => {
  const navigate = useNavigate();

  const _ = new ServiceService();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const { id } = useParams();

  const fetch = () => {
    _.fetchService(id).then((res) => {
      setTitle(res.data.title);
      setDescription(res.data.description);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <React.Fragment>
      <Preloader />
      <Header />
      <ToastContainer />
      <ServicePageTitle title="Service Details" />
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 justify-content-center d-flex px-5 mt-5">
          <div style={{ height: "auto" }} className={`text-justify mt-30`}>
            <h4 className="title title- text-primary mt-5" style={{ fontSize: "1.5em" }}>
              {title}
            </h4>
            <p style={{ lineHeight: 3 }}>{description}</p>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12">
          <img className="mt-3" src={ServiceImage} alt="" />
        </div>
      </div>
      <InquiryForm />
    </React.Fragment>
  );
};

export default ServiceDetails;
