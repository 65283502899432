import axios from "axios";
import config from '../config.json';

export class TaasService {

    requestService(data){
        return axios.post(`${config.prod}/taas/post`,data).then((data)=>data.data);
    }

    getSkills(){
        return axios.get(`${config.prod}/required_skills/findAll`).then((res)=>res.data);
    }

    getExecutors(){
        return axios.get(`${config.prod}/executor/findAll`).then((res)=>res?.data?.data);
    }
}
