import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import Header from '../common/components/Header';
import resetImage from './login/ipact/v1-bleu.jpg';
import axiosInstance from '../utils/axiosInstance';
import config from '../config.json';

function ResetPasswordPage() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${config.prod}/user/reset-password`, {
        resetLink: token,
        newPass: newPassword,
      });

      setSuccessMessage(response.data.message);
      setErrorMessage('');
      setNewPassword('');
      setConfirmPassword('');
      navigate('/signin');
    } catch (error) {
      const errorMessage = error.response.data.error || 'Something went wrong.';
      setErrorMessage(errorMessage);
      setSuccessMessage('');
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${resetImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="header" style={{ padding: '60px' }}>
        <Header />
      </div>
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="text-center text-md-start">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2 className="fw-normal mb-3 ps-5 pb-3" style={{ letterSpacing: '1px', color: 'white', marginLeft: '80px' ,   fontFamily: 'Arial, sans-serif'}}>
                Reset Password
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="mb-4 mx-5 w-100 d-flex align-items-center">
                  <MDBIcon icon="lock" size="lg" style={{ minWidth: '35px', color: 'white' }} className="me-3" />
                  <MDBInput
                    wrapperClass="w-100"
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    size="lg"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    placeholder="Enter your new password"
                    style={{ backgroundColor: 'transparent', border: '1px solid white', color: 'white' }}
                  />
                </div>

                <div className="mb-4 mx-5 w-100 d-flex align-items-center">
                  <MDBIcon icon="lock" size="lg" style={{ minWidth: '35px', color: 'white' }} className="me-3" />
                  <MDBInput
                    wrapperClass="w-100"
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    size="lg"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    placeholder="Confirm your new password"
                    style={{ backgroundColor: 'transparent', border: '1px solid white', color: 'white' }}
                  />
                </div>

                <MDBBtn className="mb-4 px-5 mx-5 w-100" type="submit" style={{ backgroundColor: '#0052B4' }}>
                  Reset Password
                </MDBBtn>
             
              </form>

              {errorMessage && <p className="login-error-message">{errorMessage}</p>}
              {successMessage && <p className="login-success-message">{successMessage}</p>}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default ResetPasswordPage;
