import {useEffect, useState} from "react";
import TrainingSubServicesItem from "./TrainingSubServicesItem";

const TrainingSubServicesSection = props => {

    const [softSkills, setSoftSkills] = useState([]);
    const [hardsKills, setHardSkills] = useState([]);


    useEffect(() => {
        const softSkillsTemp= []
        const hardSkillsTemp= []
        props?.subServices?.map(subService => {
            if(subService.trainingSkill.toLowerCase() === "soft")
            {
                softSkillsTemp.push(subService);
            }
            else
            {
                hardSkillsTemp.push(subService);
            }

        });
        setSoftSkills(softSkillsTemp);
        setHardSkills(hardSkillsTemp);


    }, [props.subServices]);




    return (
        <section className="counter-faq-section section-gap soft-blue-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-10">
                        <div className="section-title mb-50">
                            <h2 className="title mb-10">Hard Skills </h2>
                            <p className="text-color-2">
                                Does any industry face a more complex audience journey and marketing sales process than
                                B2B
                                technology.
                            </p>
                        </div>
                        <div className="accordion-two" id="accordionExample">
                            {hardsKills &&
                                <>
                                    {
                                        hardsKills?.map((subService, index) => (
                                            <TrainingSubServicesItem
                                                index={index}
                                                key={subService.id}
                                                id={subService.id}
                                                title={subService.name}
                                                description={subService.description}
                                            />
                                        ))

                                    }
                                </>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="section-title mb-50">
                            <h2 className="title mb-10">Soft Skills </h2>
                            <p className="text-color-2">
                                Does any industry face a more complex audience journey and marketing sales process than
                                B2B
                                technology.
                            </p>
                        </div>
                        <div className="accordion-two" id="accordionExample">
                            {softSkills &&
                                <>
                                    {
                                        softSkills.map((subService, index) => (
                                            <TrainingSubServicesItem
                                                index={index+100}
                                                key={subService.id}
                                                id={subService.id}
                                                title={subService.name}
                                                description={subService.description}
                                            />
                                        ))

                                    }
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default TrainingSubServicesSection;