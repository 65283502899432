import React, { useEffect, useState } from "react";
import Scroll from "react-scroll-to-element";
import { ServiceService } from "../../services/ServiceService";
import { useParams } from "react-router-dom";
import InquiryForm from "../InquiryForm";

const ServiceItem = ({ next, image, name_, description_, color, index }) => {
  const _ = new ServiceService();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const fetch = () => {
    _.fetchService(id).then((res) => {
      setName(res?.data?.title);
      setDescription(res?.data?.description);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const textColor = index % 2 !== 0 ? "white" : "#007aff";
  const backgroundColor = color;
  const entranceAnimation = index % 2 ? "Left" : "Right";
  const imageItem = (
    <div className="col-6 p-2 d-flex justify-content-end">
      <img alt="" style={{ borderRadius: "8px", width:400, height : 400 }} src={image} />
    </div>
  );
  return (
    <section
      className="whu-section section-gap soft-blue-bg"
      id={name}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="container">
        <div className="row justify-content-center align-content-center">
          <div className="col-lg-6 col-md-10 order-lg-2"></div>
          <div className="col-lg-6 col-md-10 order-lg-1">
            <div
              className="section-title mb-50 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <span className="title-tag" style={{ color: "#007aff" }}></span>
              <h2 className="title" style={{ color: textColor }}>
                {name}
              </h2>
            </div>
          </div>
        </div>
        <div
          className={"container wow fadeIn" + entranceAnimation}
          data-wow-delay="0.3s"
        >
          <div className="row">
            {index % 2 ? imageItem : ""}
            <div
              style={{
                color: index % 2 ? "white" : "black",
                fontSize: "14px",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "40px",
              }}
              className="col-6 p-2"
            >
              {description}
            </div>
            {!(index % 2) ? imageItem : ""}
          </div>
        </div>

        <InquiryForm />
      </div>
    </section>
  );
};
export default ServiceItem;
