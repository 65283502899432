import React from "react";
import Header from "../../common/components/Header";
import Preloader from "../../common/components/Preloader";
import EventsBody from "./EventsBody";
import EventsHeader from "./EventsHeader";

const Events = () => {
    return (
        <React.Fragment>
            <Preloader/>
            <Header/>
            <EventsHeader/>
            <EventsBody/>
        </React.Fragment>
    )
}
export default Events;