import { useHttpClient } from "../../common/hooks/http-hook";
import { useState } from "react";
import { useForm } from "../../common/hooks/form-hook";
import Input from "../../common/util/Input";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../common/util/validators";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

const ContactForm = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isSending, setIsSending] = useState(false);
  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      message: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

    } else {
        placeSubmitHandler(event);

    }

    setValidated(true);
  };

  const placeSubmitHandler = async (event) => {

    event.preventDefault();
    setIsSending(true);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}contact/postcontact`,
          "POST",
          JSON.stringify({
            name: formState.inputs.name.value,
            email: formState.inputs.email.value,
            message: formState.inputs.message.value,
            formType: props.title.replace(/\s/g, ""),
          }),
          {
            "Content-Type": "application/json",
          }
        );
        setFormData(
          {
            name: {
              value: "",
              isValid: false,
            },
            email: {
              value: "",
              isValid: false,
            },
            message: {
              value: "",
              isValid: false,
            },
          },
          false
        );

        setValidated(true);
      } catch (e) {
        console.log(e);
      }
    
  };

  const [validated, setValidated] = useState(false);

  return (
    <section
      className="conatct-section-two section-gap"
      style={{ backgroundImage: "url('assets/img/contact-bg-2.jpg')" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title white-color text-center mb-40">
              <h2 className="title mb-10">Get Your {props.title}</h2>
              <p className="text-color-2">
                {" "}
                You are executive, manager or a team{" "}
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="contact-form">
              {/* {!isSending ?
                                <Form noValidate validated={validated} onSubmit={placeSubmitHandler}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group controlId="validationCustom01" className="mt-30">
                                                <Form.Control id="name"
                                                       element="input" type="text" label="Name"
                                                       style={{color:"#fff",height:70}}
                                                       readOnly={true}
                                                       disabled
                                                       placeholder="Full Name here"
                                                       onInput={inputHandler}/>
                                                <span className="icon"><i className="fal fa-user"></i></span>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group controlId="validationCustomUsername" className=" mt-30">
                                                
                                                <InputGroup hasValidation>
                                                <Form.Control id="email"
                                                        required
                                                        style={{color:"#fff",height:70}}

                                                       element="input" type="text" label="Email"
                                                       placeholder="Email here"
                                                       onInput={inputHandler}/>
                                                <span className="icon"><i className="fal fa-envelope-open"></i></span>

                                                    <Form.Control.Feedback type="invalid">
                                                        Please type correctly your email.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                               
                                            </Form.Group>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group textarea-group mt-30">
                                                <Form.Control id="message"
                                                       required
                                                       style={{color:"#fff",height:100}}

                                                       element="textarea" type="text" label="Message"
                                                       placeholder="Message here"
                                                       onInput={inputHandler}/> <span className="icon"><i
                                                className="fal fa-pencil"></i></span>
                                                 <Form.Control.Feedback type="invalid">
                                                    Please enter your message.
                                                </Form.Control.Feedback>    
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group textarea-group mt-30">
                                                <button className="main-btn small-size ml-auto mr-auto"
                                                        >Get your {" "}
                                                    {props.title}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form> : <>
                                    <div className="text-center mt-100">
                                        <div className="icon">
                                            <i style={{color: 'white'}} className="fal fa-check fa-8x"></i>
                                            <h1 className="title" style={{color: 'white'}} >Thank you for getting in touch!</h1>
                                        </div>
                                    </div>
                                </>
                            } */}
              {!isSending ? (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-white">Fullname </Form.Label>
                      <InputGroup>
                        <Form.Control
                          readOnly
                          disabled
                          required
                          type="text"
                          style={{
                            height: 70,
                            backgroundColor: "#082545",
                            opacity: 0.9,
                          }}
                          placeholder="Fullname"
                          defaultValue="Customer Name"
                          onInput={inputHandler}
                        />
                        <span className="icon">
                          <i className="fal fa-user"></i>
                        </span>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Form.Label className="text-white">
                        Receipt Email
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          onInput={inputHandler}
                          style={{ height: 70 }}
                          type="email"
                          placeholder="Email address"
                        />
                        <span className="icon">
                          <i className="fal fa-envelope"></i>
                        </span>

                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email address.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <Form.Label className="text-white">Message</Form.Label>
                      <InputGroup>
                        <Form.Control
                          style={{ height: 120 }}
                          type="textarea"
                          placeholder="Enter your Message"
                          required
                          onInput={inputHandler}
                        />
                        <span className="icon">
                          <i className="fal fa-pen"></i>
                        </span>
                        <Form.Control.Feedback type="invalid">
                          Please enter your message.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <div className="col-12">
                    <div className="input-group textarea-group mt-30">
                      <button
                        type="submit"
                        className="main-btn small-size ml-auto mr-auto"
                      >
                        Get your {props.title}
                      </button>
                    </div>
                  </div>
                </Form>
              ) : (
                <>
                  <div className="text-center mt-100">
                    <div className="icon">
                      <i
                        style={{ color: "white" }}
                        className="fal fa-check fa-8x"
                      ></i>
                      <h1 className="title" style={{ color: "white" }}>
                        Thank you for getting in touch!
                      </h1>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
