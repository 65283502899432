import React, { useState } from "react";
import axios from "axios";
import { MDBIcon } from "mdb-react-ui-kit"; 
import Header from '../common/components/Header';
import backgroundImage from './login/ipact/v1-bleu.jpg'; 
import config from '../config.json';

const ResetForm = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please enter your email address.");
      return;
    }
    try {
      const response = await axios.post(
        `${config.prod}/user/forgot-password/${email}`
      );
      setSuccessMessage("Email sent successfully.");
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("An error occurred while resetting the password.");
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <div className="header" style={{ padding: '60px' }}>
        <Header />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <form onSubmit={handleSubmit} style={{ width: '800px', padding: '40px', borderRadius: '5px', color: 'white' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px',color: 'white' ,   fontFamily: 'Arial, sans-serif' }}>Forgot Password</h2>
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="email" style={{ fontWeight: 'bold' }}>
         
              Email:</label>
            <input
           
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '10px', background: 'transparent', border: 'none', borderBottom: '1px solid #ccc', color: 'white' }}
              placeholder="Enter your email address"
            />
          </div>
          <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#0052B4', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer',   fontFamily: 'Arial, sans-serif' }}>
            Reset Password
          </button>
          {errorMessage && <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green', marginTop: '10px' }}>{successMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetForm;
