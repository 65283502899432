import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import ServicePageTitle from "../components/services/ServicePageTitle";
import ServiceDetails from "../components/services/ServiceDetails";
import ContactForm from "../components/services/ContactForm";
import ItSubServiceList from "../components/services/subServices/itDevelopment/ItSubServiceList";
import {useLocation} from "react-router-dom";
import {useHttpClient} from "../common/hooks/http-hook";
import {useEffect, useState} from "react";
import config from '../config.json';
const ItDevelopmentPage = () => {

    const {state} = useLocation();
    const {id} = state;
    const {isLoading, sendRequest, clearError} = useHttpClient();

    const [service, setService] = useState();

    useEffect(() => {
        const fecthServices = async () => {

            try {
                const serviceData = await sendRequest(`${config.prod}service/find/${id}`);
                setService(serviceData.service);

            } catch (e) {
                console.log(e);
            }

        };
        fecthServices();

    }, [sendRequest]);


    return (
        <>
            <Preloader/>
            <Header/>
            {!isLoading && service && <>
                <ServicePageTitle title="IT Development"/>
                <ServiceDetails description={service.description}/>
                <div style={{marginTop:-200}}></div>
                <ItSubServiceList subServices={service.subservices}/>

                <ContactForm title="ITDevelopment"/>
            </>
            }
        </>
    );
};

export default ItDevelopmentPage;