import axios from 'axios';
import config from '../config.json';

export class ProductService {

    fecthProduct(id){
        return axios.get(`${config.prod}/project/get/${id}`).then((res)=>res.data); 
    }

    fetchInternal()
    {
        return axios.get(`${config.prod}/project/internal`).then((res)=>res.data); 
    }

    fetchExternal()
    {
        return axios.get(`${config.prod}/project/external`).then((res)=>res.data); 
    }
}