import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import ServicePageTitle from "../components/services/ServicePageTitle";
import ServiceDetails from "../components/services/ServiceDetails";
import ContactForm from "../components/services/ContactForm";
import TrainingSubServicesSection from "../components/services/subServices/training/TrainingSubServicesSection";
import { useParams} from "react-router-dom";
import {useHttpClient} from "../common/hooks/http-hook";
import {useEffect, useState} from "react";
import axiosInstance from "../utils/axiosInstance";


const TrainingPage = () => {
    const {id} = useParams();

    const [service, setService] = useState();

    useEffect(() => {
      const fetchServices = async () => {
        axiosInstance
          .get(`/service/find/${id}`, {})
          .then((response) => {
            setService(response.data.service);
          })
          .catch((e) => {
            console.log(e);
          });
      };
      fetchServices();
    }, []);



    return (
        <>
            <Preloader/>
            <Header/>

            <ServicePageTitle title="Training"/>

            <ServiceDetails description={"Training"}/>
                <TrainingSubServicesSection subServices={service?.subservices}/>
           
            <ContactForm title={"Training"}/>
        </>
    );
};

export default TrainingPage;