import "./TeamMember.scss";
import config from "../../../config.json";

const TeamMember = (props) => {
  return (
    <div className=" col-lg-12 col-sm-12 justify-content-center d-flex align-items-center">
      <div class="card">
        <div class="card-img">
          <img alt="" src={`${config.prod}/${props.avatar}`} />
        </div>
       
        <div class="card-content">
          <p className="text-center" style={{color:"#0052b4", fontSize:18, marginTop: -10, fontWeight:'600'}}>{props.name} </p>
          <div align="center">
            <img style={{borderRadius:5}} width={50} height={30} alt="" src={`${config.prod}/${props.flag}`} />
          </div>
          
          <p style={{ fontWeight:'600'}}>{props.role}</p>
          <p className="text-primary">
            <i className="fa fa-envelope"/><small> {props.email}</small></p>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
