import React from "react";
import PartnersSlide from "./PartnersSlide";
const PartnersList = (props) => {
  return (
    <section className="about-section mt-5 pt-1">
      <div className="container mt-5 pt-5">
        <div className="section-title mb-50">
          <h2 className="title">Our partners</h2>
        </div>
        {props.partners.map((type) => {
          return (
            <PartnersSlide
              title={type["_id"]}
              partners={type.partners}
              count={type.count}
            />
          );
        })}

        <div className="mt-5"></div>
      </div>
    </section>
  );
};
export default PartnersList;
