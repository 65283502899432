import React from "react";
import Image1 from "../../../assets/img/values/community2.png";
import Image2 from "../../../assets/img/values/trust.png";
import Image3 from "../../../assets/img/values/integrity.svg";
import Image4 from "../../../assets/img/values/respect.svg";
import Image5 from "../../../assets/img/values/balance.svg";
import Image6 from "../../../assets/img/values/flexibility.svg";

import "../../../assets/css/style.css";

const Values = () => {
  return (
    <React.Fragment>
      <div className="container pt-5 mt-5">
        <p className="text-center">
          {" "}
          <i
            className="fa fa-quote-left mx-2"
            style={{ color: "#002249" }}
            aria-hidden="true"
          ></i>
          We value your business{" "}
          <i
            className="fa fa-quote-right mx-2"
            style={{ color: "#002249" }}
            aria-hidden="true"
          ></i>
        </p>

        <div className="d-flex justify-content-center align-items-center">
          <h2
            className="title text-center"
            style={{ fontSize: 65, letterSpacing: 5 }}
          >
            Core Values
          </h2>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <hr style={{ border: "0.5px solid #002249", width: 300 }} />
        </div>
        <div className="pt-4 d-flex justify-content-center align-items-center">
          <div className="row  d-flex justify-content-center align-items-center">
            <div
              style={{
                borderRadius: 0,
                paddingRight: 3,
                border: "1px solid transparent",
              }}
              className=" col-lg-2 col-md-4 col-sm-2 d-flex justify-content-center align-items-center mt-2 img-value"
            >
              <div className="row">
                <div className="col-12" align="center">
                  <img height={90} width={100} src={Image1} alt="" />
                </div>
                <div className="col-12">
                  <h4 className="mt-4 text-center">Community</h4>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: 0, border: "1px solid transparent" }}
              className=" col-lg-2 col-md-4 col-sm-2  d-flex justify-content-center align-items-center mt-2 img-value"
            >
              <div className="row">
                <div className="col-12" align="center">
                  <img height={90} width={100} src={Image2} alt="" />
                </div>
                <div className="col-12">
                  <h4 className="mt-4 text-center">Trust</h4>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: 0, border: "1px solid transparent" }}
              className=" col-lg-2 col-md-4 col-sm-2  d-flex justify-content-center align-items-center mt-2 img-value"
            >
              <div className="row">
                <div className="col-12" align="center">
                  <img height={90} width={100} src={Image3} alt="" />
                </div>
                <div className="col-12">
                  <h4 className="mt-4 text-center">Integrity</h4>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: 0, border: "1px solid transparent" }}
              className="  col-lg-2 col-md-10 col-sm-12 d-flex justify-content-center align-items-center mt-2"
            >
              <div className="row">
                <div className="col-12" align="center">
                  <img height={90} width={100} src={Image4} alt="" />
                </div>
                <div className="col-12">
                  <h4 className="mt-4 text-center">Respect</h4>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: 0, border: "1px solid transparent" }}
              className="  col-lg-2 col-md-10 col-sm-12 d-flex justify-content-center align-items-center mt-2 img-value"
            >
              <div className="row">
                <div className="col-12" align="center">
                  <img height={90} width={100} src={Image5} alt="" />
                </div>
                <div className="col-12">
                  <h4 className="mt-4 text-center">Balance</h4>
                </div>
              </div>
            </div>
            <div
              style={{ borderRadius: 0, border: "1px solid transparent" }}
              className=" col-lg-2 col-md-10 col-sm-12 d-flex justify-content-center align-items-center mt-2 "
            >
             <div className="row">
                <div className="col-12" align="center">
                  <img height={90} width={100} src={Image6} alt="" />
                </div>
                <div className="col-12">
                  <h4 className="mt-4 text-center">Flexibility  </h4>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 pt-5"></div>
      </div>
    </React.Fragment>
  );
};

export default Values;
