import axios from "axios";
import config from '../config.json';

export class InquiryService {

    create(data){
        return axios.post(`${config.prod}/inquiry/post`,data).then((data)=>data.data);
    }

    fetch(){
        return axios.get(`${config.prod}/inquiry/findAll`).then((res)=>res.data);
    }


}
