import React from "react";

const Vision =  ()=> {

    return (
        <React.Fragment>
        <div className="container pt-5 mt-5">
          <p className="text-center">
            {" "}
            <i
              className="fa fa-quote-left mx-2"
              style={{ color: "#002249" }}
              aria-hidden="true"
            ></i>
           Our Vision is your Future{" "}
            <i
              className="fa fa-quote-right mx-2"
              style={{ color: "#002249" }}
              aria-hidden="true"
            ></i>
          </p>
  
          <div className="d-flex justify-content-center align-items-center">
            <h2
              className="title text-center"
              style={{ fontSize: 65, letterSpacing: 5 }}
            >
              Our Vision
            </h2>
          </div>
  
          <div className="d-flex justify-content-center align-items-center">
            <hr style={{ border: "0.5px solid #002249", width: 300 }} />
          </div>
          <div className="pt-4  d-flex justify-content-center align-items-center">
              <p style={{fontSize:20,width:600}} className="text-center">
              An organizational, technological and human environment suitable for the fulfillment of your expected goals
                </p>
  
          </div>
        </div>
      </React.Fragment>
    )
}

export default Vision;