import axios from "axios";
import config from '../config.json';

export class ServiceService {

    fetchService(id){
        return axios.get(`${config.prod}/service/find/${id}`).then((data)=>data.data);
    }

 
}
