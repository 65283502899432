import React, { useEffect, useRef, useState } from "react";
import EventItem from "./EventItem";
import axios from "axios";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import config from '../../../config.json';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function EventsList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${config.prod}/events/list`);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  

  useEffect(()=> {
    document.getElementById("events").scrollIntoView({block:'start'})

  },[]);

  return (
    <section  id="events" className="portfolio-area section-gap mt-5 pt-3">
      <div className="container mt-5 pt-5">
        <div className="section-title mb-50">
          <h2 className="title">Our News</h2>
         
        </div>
        <Carousel responsive={responsive}>
          {events.map((event) => (
            <div align="center" className="container row justify-content-center align-items-center">
              <div key={event._id} className="col-12 mb-4">
                <EventItem event={event} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="mt-5"></div>
    </section>
  );
}

export default EventsList;
