import React from "react";
import Header from "../../common/components/Header";
import Preloader from "../../common/components/Preloader";
import Mission from "../../components/About/Mission/Mission";
import Values from "../../components/About/Values/Values";
import Vision from "../../components/About/Vision/Vision";
import AboutUsHeader from "./AboutUsHeader";

const AboutUsPage = () => {
   


    return (
        <React.Fragment>
               <Preloader/>
                <Header/>
                <AboutUsHeader/>
                <Mission/>
                <Vision/>
                <Values/>
               
        </React.Fragment>
    )
}

export default AboutUsPage;