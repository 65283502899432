import TeamMember from "./TeamMember";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const TeamList = (props) => {
  const navigate = useNavigate();

  const onClickHandler = (e) => {
    e.preventDefault();
    navigate("team", { state: { developers: props.developers } });
  };
  return (
    <section
      className="team-area section-gap position-relative"
      style={{ backgroundColor: "#0052b4" }}
    >
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-5">
            <div className="section-title text-center mb-60">
              <h2 className="title mb-10" style={{ color: "white" }}>
                Our Team{" "}
              </h2>
              <p className="text-color-2">
                Does any industry face a more complex audience journey and
                marketing sales process than B2B technology.
              </p>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {props.developers.map((developer, index) => (
            <div className=" container d-flex">
              <TeamMember
                index={index}
                key={developer.id}
                id={developer.id}
                name={developer.firstname + " " + developer.lastname}
                role={developer.role}
                email={developer.email}
                avatar={developer.avatar}
                flag = {developer.flag}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};
export default TeamList;
