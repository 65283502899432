const HistoryHeader = props => {

    return (
        <section  className="page-title-area "
        style={{ backgroundImage: "url(assets/img/banner/banner-03.jpg)" }}>
            <div className="container">
                <h2 className="title text-center text-white">Our history</h2>
                <ul className="breadcrumb-nav">
                    <li><a href="https://www.ipactconsult.com">Home</a></li>
                    <li className="active">About</li>
                </ul>
            </div>
        </section>
    );
};

export default HistoryHeader;