/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Logout from "../../pages/Logout";
import './home.css';
const HomeHeader = (props) => {

  const [formIsOpen, setFormIsOpen] = useState(false);
  const user = useSelector((state) => state.user.user);

  const openForm = () => {
    setFormIsOpen(true);
  };

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/signin");
  }

  const goToRegister = () => {
    navigate("/signup");
  }

  const onClickHandler = (e) => {
    e.preventDefault();
    navigate("services/" + props.title.replace(/\s/g, ""), {
      state: { id: props.id },
    });
  };

  const goToService = () => {
    // Utilisez la méthode scrollIntoView pour faire défiler la section dans la vue.
    const serviceSection = document.querySelector("#services");
    serviceSection.scrollIntoView({ behavior: "smooth" });
    const sectionName = "services"; // Remplacez cela par le nom de votre section
    window.history.pushState(null, null, `/#/#${sectionName}`);
  }

  const goToProducts = () => {
    // Utilisez la méthode scrollIntoView pour faire défiler la section dans la vue.
    const serviceSection = document.querySelector("#projects");
    serviceSection.scrollIntoView({ behavior: "smooth" });
    const sectionName = "projects"; // Remplacez cela par le nom de votre section
    window.history.pushState(null, null, `/#/#${sectionName}`);
  }

  const goToNews = () => {
    // Utilisez la méthode scrollIntoView pour faire défiler la section dans la vue.
    const serviceSection = document.querySelector("#events");
    serviceSection.scrollIntoView({ behavior: "smooth" });
    const sectionName = "events"; // Remplacez cela par le nom de votre section
    window.history.pushState(null, null, `/#/#${sectionName}`);
  }

  const goToContact = () => {
    // Utilisez la méthode scrollIntoView pour faire défiler la section dans la vue.
    const serviceSection = document.querySelector("#contact");
    serviceSection.scrollIntoView({ behavior: "smooth" });
    const sectionName = "contact"; // Remplacez cela par le nom de votre section
    window.history.pushState(null, null, `/#/#${sectionName}`);
  }

  const handleClick = () => {
    document.getElementById("home");

  }

  return (
    <header className="header-four header-full-width sticky-header">
      <div className="header-navigation">
        <Navbar bg="" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/#/#home">
              <div className="site-logo" style={{ marginTop: 0 }}>
              <a  onClick={()=>handleClick()}>

                  <img
                    src={"./assets/img/ipact.png"}
                    width="150"
                    alt="IPACT"
                    className="main-logo mt-2"
                  />
                  <img
                    src={"./assets/img/ipact.png"}
                    width="150"
                    alt="IPACT"
                    className="sticky-logo"
                  />
                </a>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                style={{ marginLeft: "auto", marginRight: "auto" }}
                className="nav-links"
              >
               
               
              
                <Nav.Link
                  
                  onClick={()=>{goToService()}}
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  Services
                </Nav.Link>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Nav.Link
                  onClick={()=>goToProducts()}
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  Releases
                </Nav.Link>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              
                <Nav.Link
                  onClick={()=>{goToNews()}}
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  News
                </Nav.Link>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Nav.Link
                  onClick={()=>{goToContact()}}
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontWeight: "normal",
                    paddingLeft: 40,
                  }}
                >
                  Contact
                </Nav.Link>
              </Nav>
              <Nav>
              <Nav.Link
                  href="/#/inquiry"
                  className="btn-inquiry text-white"
                >
                  
                  <p className="ml-2 mt-2 text-center">Inquiry us &nbsp; <i className="fa fa-arrow-right"/></p>
                </Nav.Link>
             {/*    <NavDropdown
                  title={
                    <span
                      className="text-login text-white"
                      style={{
                        fontSize: 22,
                        fontWeight: "normal",
                        lineHeight: "1",
                      }}
                    >
                      <i
                        className="fa fa-lock"
                        style={{ marginRight: "8px" }}
                      ></i>
                      Login
                    </span>
                  }
                  id="auth-dropdown"
                >
                  {user ? (
                    <NavDropdown.Item>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: 10 }}>
                          <p style={{ marginBottom: 0 }}>
                            Welcome, {user.firstname}
                          </p>
                        </div>
                      </div>
                      <Logout />
                    </NavDropdown.Item>
                  ) : (
                    <React.Fragment>
                      <button onClick={goToLogin} className="nav-item btn"  >Signin</button>
                      <button onClick={goToRegister} className="nav-item btn">Signup</button>
                    </React.Fragment>
                  )}
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className="d-flex justify-content-center align-items-center" style={{position:'relative'}}>
        <div className="arrow_container">
          <a href="#toscroll">
            <span className="chevron"></span>
            <span className="chevron"></span>
            <span className="chevron"></span>
            <span className="text">Scroll down</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
