import Header from "../common/components/Header";
import ContactForm from "../components/services/ContactForm";
import ServiceDetails from "../components/services/ServiceDetails";
import Preloader from "../common/components/Preloader";
import ServicePageTitle from "../components/services/ServicePageTitle";
import SubServicesList from "../components/services/subServices/SubServicesList";
import {useLocation} from "react-router-dom";
import {useHttpClient} from "../common/hooks/http-hook";
import {useEffect, useState} from "react";

const CoachingPage = () => {
    const {state} = useLocation();
    const {id} = state;

    const {isLoading, sendRequest, clearError} = useHttpClient();

    const [service, setService] = useState();

    useEffect(() => {
        const fecthServices = async () => {

            try {
                const serviceData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}service/find/${id}`);
                setService(serviceData.service);
                


            } catch (e) {
                console.log(e);
            }

        };
        fecthServices();

    }, [sendRequest]);


    return (
        <div>
            <Preloader/>
            <Header/>
            {!isLoading && service && <>
                <ServicePageTitle title="Coaching"/>

                <div className=""></div>
                <SubServicesList description={service.description} subServices={service.subservices}/>
                <ContactForm title={"Coaching"}/>
            </>
            }
        </div>
    );
};

export default CoachingPage;