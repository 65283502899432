import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBIcon } from 'mdb-react-ui-kit';
import './css/register.css';
import Header from '../../common/components/Header';
import login from '../login/ipact/v1-bleu.jpg';
import axiosInstance from "../../utils/axiosInstance";
import axios from 'axios';
import config from '../../config.json';

const CustomInput = ({ icon, size, wrapperClass, labelClassName, id, type, name, value, onChange, required, maxLength, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getIconClassName = () => {
    const baseClassName = `me-3 ${isFocused ? 'focused-icon' : ''}`;
    const whiteIcons = ['user', 'envelope', 'lock', 'users', 'phone', 'globe','building'];
    return whiteIcons.includes(icon) ? `${baseClassName} text-white` : baseClassName;
  };

  return (
    <div className={`${wrapperClass} input-group mb-4`} onFocus={handleFocus} onBlur={handleBlur}>
      <div className="input-group-prepend">
        <span className="input-group-text" style={{ backgroundColor: 'transparent', border: '1px solid white' }}>
          <MDBIcon icon={icon} size={size} className={getIconClassName()} />
        </span>
      </div>
      <MDBInput
        wrapperClass="mb-0 flex-grow-1"
        labelClassName={labelClassName}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        placeholder={placeholder}
        style={{ backgroundColor: 'transparent', color: 'white', border: '1px solid white' }}
      />
    </div>
  );
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    passwordVerify: '',
    role: 'personal',
    company: '',
    phone: '',
    country: '',
  });
  const [buttonSize, setButtonSize] = useState('md');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [error, setError] = useState('');
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [phoneFlagUrl, setPhoneFlagUrl] = useState('');

  useEffect(() => {
    const fetchPhoneFlag = async () => {
      try {
        const response = await axios.get(`${config.prod}/user/phone-flag/${formData.country}`);
        setPhoneFlagUrl(response.data.flagUrl);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPhoneFlag();
  }, [formData.country]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeRecaptcha = (response) => {
    setRecaptchaResponse(response);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.prod}/user/registerUser`, {	
        ...formData,	
       // recaptchaToken: recaptchaResponse,	
      });
      setRegistrationSuccess(true);
      setError('');
      navigate('/signin');
    } catch (error) {
      if (error?.response?.data?.msg) {
        setError(error.response.data.msg);
      } else {
        setError('An error occurred during registration.');
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${login})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="header" style={{ padding: '60px' }}>
        <Header />
      </div>
      <div className="my-4"></div>
      <MDBContainer fluid className="p-4 background-radial-gradient overflow-hidden">
        <MDBRow className="justify-content-center">
          <MDBCol md="6" lg="4">
            <MDBCard className="my-5 bg-transparent">
              <MDBCardBody className="p-4">
                <h2
                  className="fw-normal mb-3 ps-5 pb-3"
                  style={{
                    letterSpacing: 'px',
                    color: 'white',
                    fontFamily: 'Arial, sans-serif',
              
                    textAlign: 'center',
                  }}
                >
                  Sign Up
                </h2>
                <form onSubmit={handleSubmit}>
                  <CustomInput
                    icon="user"
                    size="lg"
                    wrapperClass="mb-4"
                    labelClassName="form-label"
                    id="form1"
                    type="text"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                    required
                    placeholder="Enter your first name"
                  />

                  <CustomInput
                    icon="user"
                    size="lg"
                    wrapperClass="mb-4"
                    labelClassName="form-label"
                    id="form2"
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                    placeholder="Enter your last name"
                  />

                  <CustomInput
                    icon="envelope"
                    size="lg"
                    wrapperClass="mb-4"
                    labelClassName="form-label"
                    id="form3"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter your email address"
                  />

                  <CustomInput
                    icon="lock"
                    size="lg"
                    wrapperClass="mb-4"
                    labelClassName="form-label"
                    id="form4"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    placeholder="Enter your password"
                  />

<div className="mb-4 d-flex align-items-center">
          <MDBIcon icon="users" size="lg" className="me-3 mb-3" style={{ minWidth: '35px', marginLeft: '10px' }} />
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="role"
              value="personal"
              checked={formData.role === 'personal'}
              onChange={handleChange}
              style={{ color: 'white' ,marginLeft: '20px'  }}
            />
            <label className="form-check-label me-3" style={{ color: 'white', marginLeft: '40px' }}>
              Personal
            </label>

            <input
              className="form-check-input"
              type="radio"
              name="role"
              value="business"
              checked={formData.role === 'business'}
              onChange={handleChange}
              style={{ color: 'white', marginLeft: '60px' }}
            />
            <label className="form-check-label" style={{ color: 'white', marginLeft: '80px' }}>
              Business
            </label>
          </div>
        </div>


                  {formData.role === 'business' && (
                    <CustomInput
                      icon="building"
                      size="lg"
                     
                      ClassName="form-label"
                      id="company"
                      name="company"
                      type="text"
                      wrapperClass="mb-0 flex-grow-1"
                      value={formData.company}
                      onChange={handleChange}
                      required={formData.role === 'business'}
                      maxLength={50}
                      placeholder="Enter your company"
                    />
                  )}

                  <CustomInput
                    icon="phone"
                    size="lg"
                   
                    labelClassName="form-label"
                    id="form6"
                    type="text"
                    wrapperClass="mb-0 flex-grow-1"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    maxLength={15}
                    placeholder="Enter your phone"
                  />

                  <CustomInput
                    icon="globe"
                    size="lg"
                    wrapperClass="mb-4"
                    labelClassName="form-label"
                    id="form7"
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    maxLength={50}
                    placeholder="Enter your country"
                  />

                {/*   <div className="mb-4">
                    <ReCAPTCHA sitekey="6LecofsmAAAAAAYEiVxmHVCAc6YPv5vjdaJP2uMO" onChange={handleChangeRecaptcha} />
                  </div> */}
                  {error && <p className="register-error-message">{error}</p>}
                  <MDBBtn className="w-100 mb-4" type="submit" style={{ backgroundColor: '#0052B4', fontFamily: 'Arial, sans-serif' }}>
                    Sign up
                  </MDBBtn>
                  <div className="text-center mt-4">
                    <p>or sign up with:</p>
                    <MDBBtn tag="a" color="none" className="mx-3" style={{ color: '#1266f1' }}>
                      <MDBIcon fab icon="facebook-f" size="sm" />
                    </MDBBtn>
                    <MDBBtn tag="a" color="none" className="mx-3" style={{ color: '#1266f1' }}>
                      <MDBIcon fab icon="twitter" size="sm" />
                    </MDBBtn>
                    <MDBBtn tag="a" color="none" className="mx-3" style={{ color: '#1266f1' }}>
                      <MDBIcon fab icon="google" size="sm" />
                    </MDBBtn>
                    <MDBBtn tag="a" color="none" className="mx-3" style={{ color: '#1266f1' }}>
                      <MDBIcon fab icon="github" size="sm" />
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default RegisterForm;
