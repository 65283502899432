const FindusHeader = props => {

    return (
        <section className="page-title-area">
            <div className="container">
                <h2 className="title">Find us</h2>
                <ul className="breadcrumb-nav">
                    <li><a href="https://www.ipactconsult.com">Home</a></li>
                    <li className="active">About</li>
                </ul>
            </div>
        </section>
    );
};

export default FindusHeader;