/* eslint-disable jsx-a11y/anchor-is-valid */

const About = (props) => {
  return (
    <section className="counter-faq-section" style={{minHeight: '100vh', backgroundColor:"#013174"}}>
      <div className="container" id="toscroll">
        <div className="row align-items-center justify-content-center pt-5 pb-5 mb-5">
          <div className="col-lg-6 pt-5 mt-5 col-md-10">
            <div className="section-title mb-50">
              <h2 className="title text-white">Why choose us ?</h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 mt-100">
            <div className="section-title mb-50">
              <div className="accordion-one"  id="accordionExample">
                {
                  <>
                    {props.data.map((about, index) => (
                      <div key={index} className="" style={{backgroundColor:"#013174"}}>
                        <div className="card-header" id="headingOne" style={{backgroundColor:"#0052b4"}}>
                          <a
                            className="text-white"
                            href="#"
                            data-toggle="collapse"
                            data-target={`#collapse${index}`}
                            aria-expanded="true"
                            aria-controls={`collapse${index}`}
                            style={{ fontWeight: "bolder" }}
                            
                          >
                            {/* <i className="fal fa-long-arrow-right"></i> */}
                             {about.title}
                          </a>
                        </div>

                        <div
                          id={`collapse${index}`}
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body text-justify text-white">
                            <div>{about.description}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                }

             
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
