/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Slider from "react-slick";
import Marquee from 'react-fast-marquee';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersSlide = (props) => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 3,
    autoplaySpeed: 2000,
    centerMode: true,
    focusOnSelect: true,
    pauseOnHover: false,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="about-section mt-5 pt-1">
      <div className="container mt-5 pt-5">
        <div className="section-title mb-50">
          <h3 className="title" style={{ fontSize: "30px" }}>
            {props.title}
          </h3>
        </div>

        <section className="customer-logos slider" style={{}}>
          <Marquee >
            {props.partners.map((partner) => {
              return (
                <div className="mx-5" key={partner._id}>
                  <img
                    height="150px"
                    src={'https://api.ipactconsult.com/' + partner.image}
                    alt={partner.title}
                    style={{ display: "block", margin: "0 auto" }}
                  />
                </div>
              );
            })}
          </Marquee>
        </section>
      </div>
    </section>
  );
};

export default PartnersSlide;
