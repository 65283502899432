const PreviewTaasHeader = (props) => {
    return (
      /*   <section className="page-title-area">
              <div className="container">
                  <h2 className="title">{props.title}</h2>
                  <ul className="breadcrumb-nav">
                      <li><a href="https://www.ipactconsult.com">Home</a></li>
                      <li className="active">Services</li>
                  </ul>
              </div>
          </section> banner-section-three */
      <section
        className="page-title-area"
        style={{ backgroundImage: "url(assets/img/banner/banner-03.jpg)" }}
        id="home"
      >
        <div className="container position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="banner-content text-center">
                <div className="container" style={{marginTop:-100}}>
                <ul className="breadcrumb-nav d-flex justify-content-center align-items-center">
                    
                    <li className="active" style={{color:"#007AFF"}}>Take a look <hr style={{border:"1px solid #007AFF",width:200}}/> </li>
                  </ul>
                  <h2 className="title text-white">Project Preview</h2>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </section>
    );
  };
  
  export default PreviewTaasHeader;
  