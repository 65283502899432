import { Link } from "react-router-dom";

const ServicesHeader = (props) => {
  return (
    <section
      className="page-title-area "
      style={{ backgroundImage: "url(assets/img/banner/banner-03.jpg)" }}
    >
      <div className="container">
        <h2 className="title text-white text-center">What We Do </h2>
        <ul className="breadcrumb-nav">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Services</li>
        </ul>
      </div>
    </section>
  );
};

export default ServicesHeader;
