import React, { useEffect, useState, useMemo } from "react";
import "../scss/taas.scss";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import { InquiryService } from "../services/InquiryService";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import countryList from "react-select-country-list";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

function InquiryForm() {
  const _ = new InquiryService();
  const options = useMemo(() => countryList().getData(), []);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Si la case est cochée, ajoutez-la à la liste
      setSelectedOptions((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      // Si la case est décochée, retirez-la de la liste
      setSelectedOptions((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [companyname, setCompanyname] = useState("");
  const [companylocation, setCompanylocation] = useState("");
  const [typeservice, setTypeservice] = useState("");
  const [description, setDescription] = useState("");
  const [goal, setGoal] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleRequest = async () => {
    await _.create({
      firstname,
      lastname,
      email,
      phone,
      companyname,
      companylocation,
      typeservice: selectedOptions,
      description,
      goal,
    }).then((res) => {
      setCurrentStep(currentStep+1);
      toast.info("Your request has been sent with success.");
      setSubmitted(true);

      setTimeout(() => {
        navigate("/");
      }, 5000);
    });
  };

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const navListItems = document.querySelectorAll("div.setup-panel div a");
    const allWells = document.querySelectorAll(".setup-content");
    const allNextBtn = document.querySelectorAll(".nextBtn");
    const allPrevBtn = document.querySelectorAll(".prevBtn");

    // Initially hide all step content except the first step
    allWells.forEach((well) => {
      well.style.display = "none";
    });
    allWells[currentStep - 1].style.display = "block";

    const handleClick = (e) => {
      e.preventDefault();
      const targetId = e.target.getAttribute("href").slice(1);

      // Use data-step attribute to determine the step index
      const targetStep = parseInt(e.target.getAttribute("data-step"), 10);

      if (targetStep && targetStep !== currentStep) {
        setCurrentStep(targetStep);

        // Hide all step content and display the clicked step
        allWells.forEach((well) => {
          well.style.display = "none";
        });
        document.getElementById(targetId).style.display = "block";
      }
    };

    navListItems.forEach((item) => {
      item.addEventListener("click", handleClick);
    });

    const handleNextClick = () => {
      const curStep = document.getElementById(`step-${currentStep}`);
      const curInputs = curStep.querySelectorAll(
        'input[type="text"],input[type="url"]'
      );
      let isValid = true;

      curStep
        .querySelectorAll(".form-group")
        .forEach((group) => group.classList.remove("has-error"));

      curInputs.forEach((input) => {
        if (!input.validity.valid) {
          isValid = false;
          input.closest(".form-group").classList.add("has-error");
        }
      });

      if (isValid) {
        const nextStep = currentStep + 1;
        if (nextStep <= allWells.length) {
          setCurrentStep(nextStep);
        }
      }
    };

    allNextBtn.forEach((btn) => {
      btn.addEventListener("click", handleNextClick);
    });

    const handlePrevClick = () => {
      const prevStep = currentStep - 1;
      if (prevStep >= 1) {
        setCurrentStep(prevStep);
      }
    };

    allPrevBtn.forEach((btn) => {
      btn.addEventListener("click", handlePrevClick);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      navListItems.forEach((item) => {
        item.removeEventListener("click", handleClick);
      });

      allNextBtn.forEach((btn) => {
        btn.removeEventListener("click", handleNextClick);
      });

      allPrevBtn.forEach((btn) => {
        btn.removeEventListener("click", handlePrevClick);
      });
    };
  }, [currentStep]);

  return (
    <>
      <ToastContainer />
      <div className="container ">
        <div className=" row mt-3">
          <div className="col-lg-6 col-sm-12 mt-5">
            <h1>
              Build Your <span style={{ color: "#007aff" }}>Dream</span>{" "}
              <hr
                style={{
                  width: 180,
                  marginTop: 5,
                  border: "3px solid #07294E",
                }}
              />
            </h1>
          </div>
          <div className="col-lg-6"></div>
        </div>
        <div className="container stepwizard col-md-offset-3">
          <div  className="stepwizard-row setup-panel">
            <div className="stepwizard-step">
              <a
                style={{ fontWeight: "bold", width: 50, borderRadius: 100 }}
                href="#step-1"
                type="button"
                className={`pt-2 btn ${
                  currentStep === 1 ? "btn-primary" : "btn-default"
                }`}
                data-step={1}
              >
                <i className="fa fa-user" />
                <hr />
              </a>
              <div className="container d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600" }}>1. Personal information</p>
              </div>
            </div>
            <div className="stepwizard-step">
              
              <a
                style={{ fontWeight: "bold", width: 50, borderRadius: 100 }}
                href="#step-2"
                type="button"
                className={`pt-2 btn ${
                  currentStep === 2 ? "btn-primary" : "btn-default"
                }`}
                data-step={2}
              >
                <i className="fa fa-home" />

                <hr />
              </a>
              <div className="container d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600" }}>2. Inquiry Details</p>
              </div>
            </div>
            <div className="stepwizard-step">
              <a
                style={{ fontWeight: "bold", width: 50, borderRadius: 100 }}
                href="#step-3"
                type="button"
                className={`pt-2 btn ${
                  currentStep === 3 ? "btn-primary" : "btn-default"
                }`}
                data-step={3}
              >
                <i className="fa fa-check" />
                <hr />
              </a>
              <div className="container d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600" }}>3. Confirmation</p>
              </div>
            </div>
          </div>
        </div>

        {/* Render your form content for each step here */}
        <div className="row setup-content" id="step-1">
          {/* Step 1 form fields */}

          <div className="d-flex">
            <div className="form-group col-6">
              <label>
                <small>Firstname</small>
              </label>
              <input
                type="text"
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-": !firstname,
                  })
                }
                name="projectName"
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                style={{ height: 72 }}
              />
              {!firstname && (
                <small className="text-danger">No value present.</small>
              )}
            </div>
            <div className="form-group col-6">
              <label>
                <small>Lastname</small>
              </label>
              <input
                style={{ height: 72 }}
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-": !lastname,
                  })
                }
                name="description"
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
              {!lastname && (
                <small className="text-danger">No value present.</small>
                )}
            </div>
          </div>

          <div className="d-flex">
            <div className="form-group col-6">
              <label>
                <small>Email Address</small>
              </label>
              <input
                style={{ height: 72 }}
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-": !email,
                  })
                }
                name="description"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {!email && 
                <small className="text-danger">No value present.</small>
              }
            </div>
            <div className="form-group col-6">
              <label>
                <small>Phone Number</small>
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={(e) => setPhone(e)}
                className={classNames({
                  "px-2  p-invalid  border-": !phone,
                })}
                style={{ height: 'auto', border:'1px solid #CED4DA',borderRadius : 3 }}
              />

              {!phone && 
                <small className="text-danger">No value present.</small>
              }
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              disabled={!firstname || !lastname || !email || !phone}
              className="btn btn-primary"
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Next <i className="fa fa-arrow-right" />
            </button>
          </div>
        </div>
        <div className="row setup-content" id="step-2">
          {/* Step 2 form fields */}
          <div className="d-flex">
            <div className="form-group col-6">
              <label>
                <small>Main goal</small>
              </label>
              <input
                style={{ height: 60 }}
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-": !companyname || companyname,
                  })
                }
                name="functionalities"
                onChange={(e) => {
                  setCompanyname(e.target.value);
                }}
              />
              {!companyname && (
                <small className="text-danger">No value present.</small>
                )}
            </div>
            <div className="form-group col-6">
              <label>
                <small>Project location</small>
              </label>

              <select
                style={{ height: 60 }}
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-": !companylocation || companylocation,
                  })
                }
                selected=""
                value={companylocation}
                onChange={(e) => setCompanylocation(e.target.value)}
              >
                <option value="">Select location</option>
                {options.map((item, index) => (
                  <option key={index}>{item.label}</option>
                ))}
              </select>

              {!companylocation && (
                <small className="text-danger">No value present.</small>
                )}
            </div>
          </div>

          <div className="d-block">
            <div className="form-group col-12">
              <label>
                <small>What types of assistance do you require?</small>
              </label>
            </div>
            <div className="form-group col-12">
              <label for="">
                <input
                  type="checkbox"
                  value="Consulting"
                  onChange={handleSelectChange}
                />
               &nbsp; Consulting
              </label>
              <br/>
              <label for="">
                <input
                  type="checkbox"
                  value="Team as a service"
                  onChange={handleSelectChange}
                />
               &nbsp; Team as a service
              </label>
              <br/>
              <label for="">
                <input
                  className=""
                  type="checkbox"
                  value="Training"
                  onChange={handleSelectChange}
                />
               &nbsp; Training
              </label>
              <br/>
              <label for="">
                <input
                  type="checkbox"
                  value="HR outsourcing"
                  onChange={handleSelectChange}
                />
               &nbsp; HR outsourcing
              </label>
              <br/>
              {!selectedOptions.length && (
                <small className="text-danger">No value present.</small>
                )}
              <ul className="d-flex mt-3">
                {selectedOptions.map((item, index) => (
                  <li key={index}>
                    <p
                      className="alert alert-primary alert-sm mx-2"
                      style={{ borderRadius: 100 }}
                    >
                      {item}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="d-flex">
            <div className="form-group col-12">
              <label>
                <small>What specific details can you provide about your inquiry?</small>
              </label>
              <textarea
                rows={5}
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-": !description,
                  })
                }
                name="functionalities"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              {!description && (
                <small className="text-primary">Optional field.</small>
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="form-group col-12">
              <label>
                <small>When would you like to achieve your project ?</small>
              </label>
              <input
                type="date"
                style={{ height: 60 }}
                className={
                  "form-control" +
                  classNames({
                    " p-invalid  border-danger": !goal,
                  })
                }
                name="functionalities"
                onChange={(e) => {
                  setGoal(e.target.value);
                }}
              />
              {!goal && <small className="text-danger">No value present.</small>}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="d-flex ">
              <button
                className="btn btn-light"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                <i className="fa fa-arrow-left" /> Previous
              </button>
            </div>
            &nbsp;&nbsp;
            <div className="d-flex justify-content-end align-items-end">
              <button
                onClick={handleRequest}
                disabled={submitted}
                className="btn btn-primary pt-2  "
              >
                <i className="fa fa-check" /> Book Now
              </button>
            </div>
          </div>
        </div>
        <div className="row setup-content" id="step-3">
          <div className="form-group">
           
            {submitted && (
              <React.Fragment>
                <Loader />
                <br />
                <p
                  className="text-center text-primary"
                  style={{ fontWeight: "bold" }}
                >
                  <i className="fa fa-check" />
                  Your request has been sent with success
                </p>
              </React.Fragment>
            )}
          </div>

          <div className="d-flex justify-content-center">
            
            {/* <div className="justify-content-end">
              <button
                disabled={
                  !projectName |
                  !projectDescription |
                  !projectFeatures |
                  !selectedOptions.length |
                  !cart.length
                }
                onClick={handleRequest}
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default InquiryForm;
