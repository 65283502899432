import React, { useEffect, useState } from "react";
import "../scss/taas.scss";
import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import ServicePageTitle from "../components/services/ServicePageTitle";
import { TaasService } from "../services/TaasService";
import { useSelector } from "react-redux";
import "../scss/team.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import Moment from 'react-moment';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function TeamAsServicePage() {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    if (selectedOption) {
      const selectedObject = {
        id: selectedOption.value,
        skill: selectedOption.textContent, // Vous pouvez également stocker d'autres données si nécessaire
      };

      // Ajoutez l'objet à la liste des objets sélectionnés
      setSelectedOptions([...selectedOptions, selectedObject]);
      localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));

    }
  };

  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectFeatures, setProjectFeatures] = useState("");

  const [skillsR, setSkillsR] = useState([]);
  const [executors, setExecutors] = useState([]);
  const taas = new TaasService();
  const user_ = useSelector((state) => state.user.user);
  const accessToken = localStorage.getItem("accessToken" || "token");

  const [notes, setNotes] = useState("");



  useEffect(() => {
    const fetch = () => {
      taas.getSkills().then((res) => {
        setSkillsR(res.data);
      });
    };

    const fetchEx = () => {
      taas.getExecutors().then((res) => {
        setExecutors(res);
      });
    };

    fetch();
    fetchEx();
  }, []);

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  const addToCart = (item) => {
    if (!accessToken) {
      toast.error("You should be logged in to perform this operation.");
    } else {
      // Vérifier si l'élément est déjà dans le panier en utilisant son _id
      const isAlreadyInCart = cart.some(
        (cartItem) => cartItem._id === item._id
      );

      if (isAlreadyInCart) {
        toast.warn("This member is already added to your cart.");
      } else {
        // Ajouter l'élément au panier
        setCart([...cart, item]);
        console.log(item);
        toast.info("Team member is added to your cart.");
      }
    }
  };

  useEffect(() => {
    // Update localStorage when the cart changes
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const navListItems = document.querySelectorAll("div.setup-panel div a");
    const allWells = document.querySelectorAll(".setup-content");
    const allNextBtn = document.querySelectorAll(".nextBtn");
    const allPrevBtn = document.querySelectorAll(".prevBtn");

    // Initially hide all step content except the first step
    allWells.forEach((well) => {
      well.style.display = "none";
    });
    allWells[currentStep - 1].style.display = "block";

    const handleClick = (e) => {
      e.preventDefault();
      const targetId = e.target.getAttribute("href").slice(1);

      // Use data-step attribute to determine the step index
      const targetStep = parseInt(e.target.getAttribute("data-step"), 10);

      if (targetStep && targetStep !== currentStep) {
        setCurrentStep(targetStep);

        // Hide all step content and display the clicked step
        allWells.forEach((well) => {
          well.style.display = "none";
        });
        document.getElementById(targetId).style.display = "block";
      }
    };

    navListItems.forEach((item) => {
      item.addEventListener("click", handleClick);
    });

    const handleNextClick = () => {
      const curStep = document.getElementById(`step-${currentStep}`);
      const curInputs = curStep.querySelectorAll(
        'input[type="text"],input[type="url"]'
      );
      let isValid = true;

      curStep
        .querySelectorAll(".form-group")
        .forEach((group) => group.classList.remove("has-error"));

      curInputs.forEach((input) => {
        if (!input.validity.valid) {
          isValid = false;
          input.closest(".form-group").classList.add("has-error");
        }
      });

      if (isValid) {
        const nextStep = currentStep + 1;
        if (nextStep <= allWells.length) {
          setCurrentStep(nextStep);
        }
      }
    };

    allNextBtn.forEach((btn) => {
      btn.addEventListener("click", handleNextClick);
    });

    const handlePrevClick = () => {
      const prevStep = currentStep - 1;
      if (prevStep >= 1) {
        setCurrentStep(prevStep);
      }
    };

    allPrevBtn.forEach((btn) => {
      btn.addEventListener("click", handlePrevClick);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      navListItems.forEach((item) => {
        item.removeEventListener("click", handleClick);
      });

      allNextBtn.forEach((btn) => {
        btn.removeEventListener("click", handleNextClick);
      });

      allPrevBtn.forEach((btn) => {
        btn.removeEventListener("click", handlePrevClick);
      });
    };
  }, [currentStep]);

  return (
    <>
      <Preloader />
      <Header />
      <ToastContainer />
      <ServicePageTitle title="Team As Service" />

      <div className="container ">
        <div className=" row mt-3">
          <div className="col-lg-6 col-sm-12 mt-5">
            <h1>
              Build Your <span style={{ color: "#007aff" }}>Team</span>{" "}
              <hr
                style={{
                  width: 180,
                  marginTop: 5,
                  border: "3px solid #07294E",
                }}
              />
            </h1>
          </div>
          <div className="col-lg-6"></div>
        </div>
        <div className="container stepwizard col-md-offset-3">
          <div className="stepwizard-row setup-panel">
            <div className="stepwizard-step">
              <a
                style={{ fontWeight: "bold" }}
                href="#step-1"
                type="button"
                className={`btn ${
                  currentStep === 1 ? "btn-primary" : "btn-default"
                }`}
                data-step={1}
              >
                A
                <hr />
              </a>
              <div className="container d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600" }}>Project information</p>
              </div>
            </div>
            <div className="stepwizard-step">
              <a
                style={{ fontWeight: "bold" }}
                href="#step-2"
                type="button"
                className={`btn ${
                  currentStep === 2 ? "btn-primary" : "btn-default"
                }`}
                data-step={2}
              >
                B
                <hr />
              </a>
              <div className="container d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600" }}>Features & Required Skills</p>
              </div>
            </div>
            <div className="stepwizard-step">
              <a
                style={{ fontWeight: "bold" }}
                href="#step-3"
                type="button"
                className={`btn ${
                  currentStep === 3 ? "btn-primary" : "btn-default"
                }`}
                data-step={3}
              >
                C
                <hr />
              </a>
              <div className="container d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600" }}>Team</p>
              </div>
            </div>
          </div>
        </div>

        {/* Render your form content for each step here */}
        <div className="row setup-content" id="step-1">
          {/* Step 1 form fields */}
          <div className="form-group">
            <label>
              <small>Project Name</small>
            </label>
            <input
              type="text"
              className={
                "form-control" +
                classNames({
                  " p-invalid  border-danger": !projectName,
                })
              }
              name="projectName"
              placeholder="Write your project name ..."
              onChange={(e) => {
                localStorage.setItem("projectName", e.target.value);
                setProjectName(e.target.value);
              }}
              style={{ height: 60 }}
            />
            {!projectName && (
              <small className="text-danger">Required field.</small>
            )}
          </div>
          <div className="form-group">
            <label>
              <small>Project Description</small>
            </label>
            <textarea
              rows={5}
              placeholder="Write a brief description of the project ..."
              className={
                "form-control" +
                classNames({
                  " p-invalid  border-danger": !projectDescription,
                })
              }
              name="description"
              onChange={(e) => {
                localStorage.setItem("projectDescription", e.target.value);
                setProjectDescription(e.target.value);
              }}
            />
            {!projectDescription && (
              <small className="text-danger">Required field.</small>
            )}
          </div>
        </div>
        <div className="row setup-content" id="step-2">
          {/* Step 2 form fields */}
          <div className="form-group">
            <label>
              <small>Functionalities</small>
            </label>
            <textarea
              className={
                "form-control" +
                classNames({
                  " p-invalid  border-danger": !projectFeatures,
                })
              }
              name="functionalities"
              rows={5}
              placeholder="Write your functionalities as hyphens ..."
              onChange={(e) => {
                localStorage.setItem("projectFeatures", e.target.value);
                setProjectFeatures(e.target.value);
              }}
            />
            {!projectFeatures && (
              <small className="text-danger">Required field.</small>
            )}
          </div>
          <div className="form-group">
            <label>
              <small>Required Skills (multiple choice)</small>
            </label>

            <select
              style={{ height: 250 }}
              className={
                "bg-primary" +
                classNames({
                  " p-invalid  border-danger": !selectedOptions,
                })
              }
              multiple={true} // Autorise la sélection multiple
              value={selectedOptions} // Utilisez la valeur de l'état
              onChange={handleSelectChange} // Gestionnaire d'événements onChange
            >
              {skillsR.map((item, index) => (
                <option
                  className="text-white"
                  style={{
                    width: "100%",
                    paddingLeft: 20,
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                  key={index}
                  value={item._id}
                >
                  {item.skill}
                </option>
              ))}
            </select>
            {!selectedOptions.length && (
              <small className="text-danger">Required field.</small>
            )}
            <ul className="d-flex mt-3">
              {selectedOptions.map((item, index) => (
                <li key={index}>
                  <p
                    className="alert alert-primary alert-sm mx-2"
                    style={{ borderRadius: 100 }}
                  >
                    {item.skill}
                  </p>
                </li>
              ))}
            </ul>
            <div style={{ marginTop: 290 }}></div>
          </div>
        </div>
        <div className="row setup-content" id="step-3">
          <div className="form-group">
            <label>
              <small>Members</small>
            </label>

            <Carousel responsive={responsive}>
              {executors.map((item, index) => (
                <div
                  key={index}
                  className="mx-2 d-flex justify-content-center align-items-center"
                >
                  <div
                    className={
                      "card" +
                      classNames({
                        " p-invalid  border-danger": !cart.length,
                      })
                    }
                    style={{ borderRadius: 5 }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <picture>
                        <img
                          height={200}
                          className=""
                          src={`${process.env.REACT_APP_BACKEND_URL}uploads/${item.avatar}`}
                          alt=""
                        />
                      </picture>
                    </div>
                    <div className="card__content">
                      <p className="card__tag">
                        {item.firstname} {item.lastname}
                      </p>
                      <h6 className="card__title text-primary">{item.role}</h6>
                      <div className=" d-flex row ">
                        {item.technologies.map((j) => (
                          <p style={{borderRadius:50, fontSize:12}} className="alert alert-primary mx-1">
                            <small>
                              {"#"}
                              {j}
                            </small>
                          </p>
                        ))}
                      </div>
                     <p><i className="fa fa-clock text-primary"/>  Disponibilites</p>
                      <div className=" d-flex row ">
                        {item.datesDisponibility.map((j) => (
                            <p  style={{fontSize:12, borderRadius:50}} className="alert alert-primary mx-1">
                            <small>
                             <i className="fa fa-calendar"/> {j.substring(0,10)}
                            </small>
                          </p>
                        ))}
                      </div>
                      <p className="card__desc">
                        {item.description} <hr />
                      </p>
                      <p className="card__desc">{item.bio}</p>
                      <div className="card__price">
                        <h1>
                          {item.price === 0 ? (
                            <></>
                          ) : (
                            <React.Fragment>
                              {item.price}
                              {"$"} {item.pricePerPeriod}
                            </React.Fragment>
                          )}
                        </h1>
                      </div>
                      <button
                        onClick={() => addToCart(item)}
                        className="btn btn-primary w-100"
                      >
                        <i className="fa fa-shopping-cart" /> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>

            {!cart.length && (
              <small className="text-danger">Required field.</small>
            )}
          </div>
          <div className="form-group">
            <label>
              <small>Notes</small>
            </label>
            <textarea
              onChange={(e) => {
                localStorage.setItem("notes", e.target.value);
                setNotes(e.target.value);
              }}
              className="form-control"
              name="message"
            />
          </div>

          <div className="d-flex row">
            <div className="col-10"></div>
            <div className=" col-lg-2 col-sm-12">
              <a
                href="/services/previewTaas"
                disabled={
                  !projectName |
                  !projectDescription |
                  !projectFeatures |
                  !selectedOptions.length |
                  !cart.length
                }
                className="btn btn-primary pt-2 w-100 "
              >
                Preview
              </a>
            </div>
            {/* <div className="justify-content-end">
              <button
                disabled={
                  !projectName |
                  !projectDescription |
                  !projectFeatures |
                  !selectedOptions.length |
                  !cart.length
                }
                onClick={handleRequest}
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamAsServicePage;
