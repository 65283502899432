import { useEffect, useRef, useState } from "react";

import Preloader from "../common/components/Preloader";
import HomeHeader from "../components/HomePage/HomeHeader";
import Banner from "../components/HomePage/Banner";
import About from "../components/HomePage/About";
import ServicesList from "../components/HomePage/Services/ServicesList";
import Partners from "../components/HomePage/Partners";
import ProjectsList from "../components/HomePage/projects/ProjectsList";
import TestimonialsList from "../components/HomePage/testimonials/testimonialsList";
//import TeamList from "../components/HomePage/teams/TeamList";
//import Contact from "../components/HomePage/contact/Contact";
import EventsList from "../components/HomePage/events/EventsList";
import Contact from "../components/HomePage/contact/Contact";
import PartnersList from "../components/Partners/PartnersList";
import TeamList from "../components/HomePage/teams/TeamList";
import axios from "axios";
import config from "../config.json";

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [abouts, setAbouts] = useState();
  const [services, setServices] = useState();
  const [projects, setProjects] = useState();
  const [partners, setPartners] = useState();
  const [team, setTeam] = useState();
  // const [developers, setDevelopers] = useState();
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      axios
        .get(`${config.prod}/about/findAll`, {})
        .then((response) => {
          setAbouts(response.data.abouts);
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`${config.prod}/service/findAll`, {})
        .then((response) => {
          setServices(response.data.services);
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`${config.prod}/project/findAll`, {})
        .then((response) => {
          setProjects(response.data.projects);
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`${config.prod}/feedback/findActive`, {})
        .then((response) => {
          setFeedbacks(response.data.feedbacks);
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`${config.prod}/partner/list/categorized`, {})
        .then((response) => {
          setPartners(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`${config.prod}/developer/findAll`, {})
        .then((response) => {
          setTeam(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchItems();
    setIsLoading(false);
  }, []);



  return (
    <>
      <Preloader />
      <HomeHeader />
      <Banner />

      {!isLoading && abouts && <About data={abouts} />}
      {!isLoading && services && <ServicesList services={services} />}
      {!isLoading && projects && <ProjectsList projects={projects} />}
      {!isLoading && <EventsList />}
      <Contact />
      {!isLoading && partners && <PartnersList partners={partners} />}
      {!isLoading && team && <TeamList developers={team} />}

      {/*             <TestimonialsList feedbacks={feedbacks}/>
       */}

      {/*             {!isLoading && projects && <TeamList developers={developers}/>}
       */}
    </>
  );
};

export default HomePage;
