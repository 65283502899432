import Header from "../common/components/Header";
import Preloader from "../common/components/Preloader";
import ServicePageTitle from "../components/services/ServicePageTitle";
import ProjectDetailsList from "../components/Projects/ProjectDetailsList";
import {useLocation} from "react-router-dom";

const ProjectsPage = () => {
    const {state} = useLocation();
    const {projects} = state | undefined;


    return (
        <>
            <Preloader/>
            <Header/>
            <ServicePageTitle title="Projects"/>

            <ProjectDetailsList projectDetails={projects}/>

        </>
    );
};
export default ProjectsPage;