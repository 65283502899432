import { Link, useNavigate } from "react-router-dom";
import ServiceItem from "./ServiceItem";

import './service.css';
import { useEffect, useRef } from "react";

const ServicesList = (props) => {

  useEffect(()=> {
    document.getElementById("services").scrollIntoView({block:'start'})

  },[]);

  const navigate = useNavigate();

  const goTo = () => {
    navigate('/inquiry');
  }


  return (
    <section className="whu-section section-gap soft-blue-bg" id="services">
      <div className="container">
        <div className="row justify-content-center align-content-center">
          <div className="col-lg-6 col-md-10 order-lg-2"></div>
          <div className="col-lg-6 col-md-10 order-lg-1">
            <div
              className="section-title mb-50 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <span className="title-tag" style={{ color: "#007aff" }}></span>
              
                <h2 className="title">Our Services</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row service-items justify-content-center">
            {props.services && (
              <>
                {props.services.map((service, index) => (
                  <ServiceItem
                    index={index}
                    key={service.id}
                    id={service.id}
                    title={service.title}
                    description={service.description}
                  />
                ))}
              </>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
              <button onClick={goTo} className=" btn-inquiry-now"> <i className="fa fa-arrow-right"/>  Start Your Inquiry Now</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
