import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
} from "mdb-react-ui-kit";
import Header from "../../../common/components/Header";
import Preloader from "../../../common/components/Preloader";
import EventsHeader from "../../../pages/Events/EventsHeader";
import Rating from "./Rating.js";
import Contact from "../contact/Contact";
import config from '../../../config.json';

const Detail = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState("");
  const [commentText, setCommentText] = useState("");
  const userToken = useSelector((state) => state.user.token);
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [showReplyInput, setShowReplyInput] = useState({});
  const [showReplies, setShowReplies] = useState({});
  const [replyText, setReplyText] = useState("");
  const toggleReplyInput = (commentId) => {
    setShowReplyInput((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const toggleShowReplies = (commentId) => {
    setShowReplies((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const handleRatingChange = (e) => {
    setRating(e.target.value);
  };

  const handleStarClick = (value) => {
    setRating(value);
  };

  const addReview = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `http://localhost:5000/events/reviews/${eventId}`,
        { rating: Number(rating) }
      );

      const alertContainer = document.createElement("div");
      alertContainer.style.position = "fixed";
      alertContainer.style.top = "50%";
      alertContainer.style.left = "50%";
      alertContainer.style.transform = "translate(-50%, -50%)";
      alertContainer.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
      alertContainer.style.color = "#000";
      alertContainer.style.padding = "60px";
      alertContainer.style.borderRadius = "20px";
      alertContainer.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";

      alertContainer.style.zIndex = "1000";
      alertContainer.style.display = "flex";
      alertContainer.style.flexDirection = "column";
      alertContainer.style.alignItems = "center";

      const message = document.createElement("p");
      message.innerText = data.message;
      message.style.marginTop = "30px";
      message.style.fontSize = "30px";
      message.style.textAlign = "center";
      alertContainer.appendChild(message);

      const closeButton = document.createElement("button");
      closeButton.innerText = "Close";
      closeButton.style.backgroundColor = "blue";
      closeButton.style.color = "#fff";
      closeButton.style.border = "none";
      closeButton.style.padding = "20px 40px";

      closeButton.style.width = "120px";
      closeButton.style.height = "40px";
      closeButton.style.display = "flex";
      closeButton.style.justifyContent = "center";
      closeButton.style.alignItems = "center";
      closeButton.style.cursor = "pointer";
      closeButton.style.marginTop = "40px";
      closeButton.addEventListener("click", () => {
        document.body.removeChild(alertContainer);
      });
      alertContainer.appendChild(closeButton);

      document.body.appendChild(alertContainer);
    } catch (error) {
      console.log(error);
    }
  };
  const handleReply = (commentId) => {
    setReplyCommentId(commentId);
    setReplyText("");
  };

  const submitReply = async (commentId) => {
    try {
      await axios.post(
        `${config.prod}/events/add-reply/${eventId}/${commentId}`,
        { text: replyText },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setReplyText("");
      setReplyCommentId(null);
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  const addComment = async () => {
    try {
      const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));

      const userId = tokenPayload.id;

      console.log("Token Payload:", tokenPayload);
      console.log("User ID:", userId);

      const response = await axios.post(
        `${config.prod}/events/add-comment/${eventId}`,
        { userId: userId, text: commentText },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log(response.data);

      setCommentText("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `${config.prod}/events/get/${eventId}`
        );
        setEvent(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching event details:", error);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const renderStars = (value) => {
    const isSelected = rating >= value;
    const isHalfSelected = rating >= value - 0.5 && rating < value;

    return (
      <span
        key={value}
        onClick={() => handleStarClick(value)}
        style={{
          cursor: "pointer",
          color: isSelected || isHalfSelected ? "yellow" : "gray",
          fontSize: "30px",
          borderRadius: "50%",
          padding: "2px",
        }}
      >
        {isSelected ? (
          <FaStar />
        ) : isHalfSelected ? (
          <FaStarHalfAlt />
        ) : (
          <FaStar />
        )}
      </span>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year} / ${month} / ${day}`;
  };


  const formatNow = (now) => {
    const date = new Date(now);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year} / ${month} / ${day}`;
  };

  useEffect(()=> {
    document.getElementById("detail").scrollIntoView({block:'start'})

  },[]);

  return (
    <div>
      <Preloader />
      <Header />
      <EventsHeader title="More information" />

      <div
        className="d-flex align-items-center justify-content-center container-fluid"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        id="detail"
      >
        <div
          className="scrollable-container"
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <MDBContainer fluid className="my-5">
            <MDBRow className="justify-content-center">
              <MDBCol md="10">
                <MDBCardBody>
                  <div className="row mb-3">
                    <MDBCol
                      md="7"
                      className="order-1"
                      style={{ margin: "0", padding: "0" }}
                    >
                      {event ? (
                        <MDBCardImage
                          src={`${config.prod}/${event.image}`}
                          fluid
                          style={{
                            borderRadius: "15px",
                            height: "auto",
                            width: "100%",
                            marginLeft: "0",
                          }}
                        />
                      ) : (
                        <div>Loading Image...</div>
                      )}
                    </MDBCol>
                    <MDBCol
                      md="5"
                      className="order-2"
                      style={{ margin: "0", padding: "20px" }}
                    >
                       <span>
                      {new Date().getDate() > formatDate(event?.date).slice(12,14) ?
                        <React.Fragment><p className="alert alert-primary"> <i className="fa fa-check"/> Event passed</p></React.Fragment> : <React.Fragment></React.Fragment>
                      }
                      </span>
                      <div className="d-flex">
                     
                        <h6
                          style={{
                            padding: "10px",
                            fontSize: "24px",
                            margin: "0",
                            textDecoration: "underline",
                            color: "black",
                          }}
                          className="d-flex"
                        >
                          {event ? event.title : "Loading..."}
                        </h6>
                        <p className="mx-4 ">
                          <Rating color={"#013174"} value={event?.rating} />{" "}
                        </p>
                      </div>
                      <span
                        className="container w-100 text-justify"
                        style={{ lineHeight: 2.5 }}
                      >
                        {event?.description}
                      </span>

                      <span className="container mt-4">
                        {" "}
                        <b>Event Date :</b> {formatDate(event?.date)}
                      </span>
                     
                      {event?.eventType === "Event" ||
                      event?.eventType === "Team Building" ? (
                        <div className="container mt-5">
                        
                            <a
                              href="tel:+21625425042"
                              className="btn w-100 text-center d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: "#0052B4",
                                color: "white",
                                height: 100,
                                borderRadius: 5,
                              }}
                            >
                              {" "}
                              <h5
                                className="text-white"
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  textTransform: "none",
                                }}
                              >
                                <i className="fa fa-phone" /> Participate : +216
                                25 425 042{" "}
                              </h5>
                            </a>
                         
                        </div>
                      ) : null}
                      {/*   <label
                          style={{
                            margin: "0",
                            marginBottom: "20px",
                            color: "black",
                          }}
                          htmlFor="rating"
                        >
                          Add Review:
                        </label>
                        <div style={{ marginBottom: "10px" }}>
                          {[1, 2, 3, 4, 5].map((value) => renderStars(value))}
                        </div>
                        <button
                          type="submit"
                          onClick={addReview}
                          style={{
                            backgroundColor: "#0248a1",
                            color: "#fff",
                            border: "none",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            height:60,
                            width:250
                          }}
                        >
                          Submit Review
                        </button> */}
                    </MDBCol>
                  </div>
                </MDBCardBody>

                <div>
                  {/*  <MDBCard
                    style={{
                      borderRadius: "15px",
                      marginTop: "80px",
                    }}
                  >
                    <MDBCardBody>
                      <div>
                        <h3 style={{ color: "white" }}>Comments:</h3>
                        {event && event.comments ? (
                          event.comments.map((comment) => (
                            <div key={comment._id} className="comment mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <MDBCardImage
                                  className="rounded-circle me-3"
                                  src="https://freepngimg.com/thumb/google/66726-customer-account-google-service-button-search-logo.png"
                                  alt="avatar"
                                  width="30"
                                  height="30"
                                />
                                <div>
                                  <h6
                                    style={{ color: "black" }}
                                    className="fw-bold  mb-1"
                                  >
                                    {comment?.user?.firstname}{" "}
                                    {comment.user.lastname}
                                  </h6>
                                  <p
                                    style={{ fontSize: "15px" }}
                                    className="text-muted small mb-0 mx-3"
                                  >
                                    Created At:{" "}
                                    {new Date(
                                      comment.createdAt
                                    ).toLocaleString()}
                                  </p>
                                </div>
                              </div>

                              <p
                                style={{ color: "white" }}
                                className="mt-3 mb-4 pb-2"
                              >
                                {comment.text}
                              </p>

                              <div className="small d-flex justify-content-start">
                                <a
                                  className="d-flex align-items-center me-3 mb-3"
                                  onClick={() => toggleReplyInput(comment._id)}
                                  style={{ marginRight: "10px" }}
                                >
                                  <MDBIcon
                                    style={{ color: "white" }}
                                    far
                                    icon="comment-dots"
                                    className="me-2"
                                  />
                                  <p
                                    style={{ color: "white" }}
                                    className="mb-0"
                                  >
                                    Comment
                                  </p>
                                </a>

                                <a
                                  className="d-flex align-items-center me-3 mb-3"
                                  onClick={() => toggleShowReplies(comment._id)}
                                  style={{ marginRight: "10px" }}
                                >
                                  <p
                                    style={{ color: "white" }}
                                    className="mb-0"
                                  >
                                    Show Replies
                                  </p>
                                </a>
                              </div>

                              {showReplyInput[comment._id] && (
                                <div>
                                  <input
                                    type="text"
                                    value={replyText}
                                    onChange={(e) =>
                                      setReplyText(e.target.value)
                                    }
                                    placeholder="Type your reply..."
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      borderRadius: "5px",
                                      backgroundColor: "#ccc",
                                      marginBottom: "10px",
                                    }}
                                  />

                                  <button
                                    style={{
                                      backgroundColor: "#0248a1",
                                      color: "#fff",
                                      border: "none",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => submitReply(comment._id)}
                                  >
                                    Submit Reply
                                  </button>
                                </div>
                              )}

                              {showReplies[comment._id] && (
                                <div>
                                  {comment.replies.map((reply) => (
                                    <div
                                      key={reply._id}
                                      className="darker mb-4"
                                    >
                                      <div className="d-flex align-items-center mb-2">
                                        <div className="d-flex align-items-center">
                                          <img
                                            src="https://i.imgur.com/CFpa3nK.jpg"
                                            alt=""
                                            className="rounded-circle"
                                            width="40"
                                            height="40"
                                            style={{ marginRight: "15px" }}
                                          />

                                          <div>
                                            <h4 style={{ color: "white" }}>
                                              {reply.user.firstname}
                                            </h4>
                                            <span style={{ color: "white" }}>
                                              {" "}
                                              {new Date(
                                                reply.createdAt
                                              ).toLocaleDateString()}
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <p style={{ color: "white" }}>
                                        {reply.text}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>No comments yet.</div>
                        )}
                      </div>

                        <label style={{ color: "black" }} htmlFor="comment">
                          Leave a comment{" "}
                        </label>
                        <div className="w-100">
                          <input
                            type="text"
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            style={{
                              minWidth: "100%",
                              padding: "5px",
                              borderRadius: "5px",
                              backgroundColor: "transparent",
                              border:'1px solid #ccc'
                            }}
                          />
                          <button
                            className="mt-3"
                            onClick={addComment}
                            style={{
                              backgroundColor: "#0248a1",
                              
                              color: "#fff",
                              border: "none",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            Submit Comment
                          </button>
                        </div>
                    </MDBCardBody>
                  </MDBCard> */}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Detail;
