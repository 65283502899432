import React from 'react';

function TrainingHeader(props) {
    return (
        <section className="page-title-area">
        <div className="container">
            <h2 className="title">Training</h2>
            <ul className="breadcrumb-nav">
                <li><a href="https://www.ipactconsult.com">Home</a></li>
                <li className="active">Services</li>
            </ul>
        </div>
    </section>
    );
}

export default TrainingHeader;