import Preloader from "../common/components/Preloader";
import Header from "../common/components/Header";
import ServicePageTitle from "../components/services/ServicePageTitle";
import ServiceDetails from "../components/services/ServiceDetails";
import SubServicesList from "../components/services/subServices/SubServicesList";
import ContactForm from "../components/services/ContactForm";
import {useLocation, useParams} from "react-router-dom";
import {useHttpClient} from "../common/hooks/http-hook";
import {useEffect, useState} from "react";


const ConsultingPage = () => {

    const id = useParams();
    const {isLoading, sendRequest, clearError} = useHttpClient();

    const [service, setService] = useState();

    useEffect(() => {
        const fecthServices = async () => {

            try {
                const serviceData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}service/find/${id}`);
                setService(serviceData.service);
                console.log(serviceData.service);

            } catch (e) {
                console.log(e);
            }

        };
        fecthServices();

    }, [sendRequest]);


    return (
        <>
            <Preloader/>
            <Header/>
            {!isLoading && service && <>
                <ServicePageTitle title="Consulting"/>

                <ServiceDetails description={service.description}/>

                <SubServicesList subServices={service.subservices}/>
                <ContactForm title={"Consulting"}/>
            </>
            }

        </>
    );
}

export default ConsultingPage;